import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import { BUSINESS_TYPES, REASON_OF_INTEREST } from '../../utilities/constants';
import { COUNTRIES_LIST } from '../../utilities/countries';
import { STATES_LIST } from '../../utilities/states';
import { PROFILES, DIMENSIONS } from '../../utilities/profiles';

const Dropdown = props => {
    const { blok, register, errors, pardot } = props;

    const { name, list, label, special, required } = blok;

    const [showDimensions, setShowDimensions] = useState(false);

    const [showStates, setShowStates] = useState(false);

    const selectProfile = e => {
        if (special === `profile`) {
            let currentProfile = PROFILES.find(
                profile => profile.title === e.target.value
            );

            if (currentProfile.has_dimensions) {
                setShowDimensions(true);
            } else {
                setShowDimensions(false);
            }
        }

        if (name === `country`) {
            setShowStates(e.target.value === `US`);
        }
    };

    return (
        <SbEditable content={props.blok}>
            <div className="small select-wrapper">
                {label && (
                    <label>
                        {label} {required ? `*` : `(Optional)`}
                    </label>
                )}
                <span className="select-wrapper-inner">
                    <select
                        id={name}
                        name={name}
                        ref={register({ required: required })}
                        onChange={e => selectProfile(e)}
                        defaultValue={`DEFAULT`}
                    >
                        <option value="DEFAULT" disabled>
                            {props.blok.placeholder || `Select an option`}
                        </option>

                        {special === `countries` && <CountriesSelect />}

                        {special === `business_type` && <BusinessSelect />}

                        {special === `reason_of_interest` && (
                            <ReasonOfInterest />
                        )}

                        {special === `profile` && <ProfileSelect />}

                        {!special &&
                            list.options &&
                            list.options.map((option, i) => {
                                return (
                                    <option key={`option-${i}`} value={option}>
                                        {typeof option === `string` &&
                                            capitalizeFirst(option)}
                                    </option>
                                );
                            })}
                    </select>
                </span>

                <span
                    role="alert"
                    className={`validation-error small ${
                        errors[name] ? `show` : `hide`
                    }`}
                >
                    This field is required.
                </span>
            </div>

            {pardot &&
                name === `country` &&
                (showStates ? (
                    <StatesSelect register={register} errors={errors} />
                ) : (
                    <StatesInput register={register} errors={errors} />
                ))}

            {showDimensions ? (
                <DimensionsSelector register={register} errors={errors} />
            ) : null}
        </SbEditable>
    );
};

const capitalizeFirst = string => {
    return string[0] && string[0].toUpperCase() + string.substring(1);
};

const BusinessSelect = () => {
    return BUSINESS_TYPES.map(type => {
        return (
            <option key={`option-${type}`} value={type}>
                {type}
            </option>
        );
    });
};

const CountriesSelect = () => {
    return COUNTRIES_LIST.map(country => {
        return (
            <option key={`option-${country.code}`} value={country.code}>
                {country.name}
            </option>
        );
    });
};

const StatesInput = ({ register, errors }) => {
    return (
        <div className="state-input">
            <label>
                Region / State (Optional)
                <input
                    type="text"
                    id="territory"
                    name="territory"
                    ref={register()}
                />
            </label>
            <span
                role="alert"
                className={`validation-error small ${
                    errors[`territory`] ? `show` : `hide`
                }`}
            >
                This field is required.
            </span>
        </div>
    );
};

const StatesSelect = ({ register, errors }) => {
    return (
        <div className="small select-wrapper">
            <label>Region / State *</label>
            <span className="select-wrapper-inner">
                <select
                    id="state"
                    name="state"
                    ref={register({
                        validate: value => value !== `DEFAULT`,
                        required: true,
                    })}
                    defaultValue={`DEFAULT`}
                >
                    <option value="DEFAULT" disabled>
                        Select a state
                    </option>

                    {STATES_LIST.map(state => {
                        return (
                            <option
                                key={`option-${state.abbreviation}`}
                                value={state.abbreviation}
                            >
                                {state.name}
                            </option>
                        );
                    })}
                </select>
            </span>

            <span
                role="alert"
                className={`validation-error small ${
                    errors[`state`] ? `show` : `hide`
                }`}
            >
                This field is required.
            </span>
        </div>
    );
};

const ReasonOfInterest = () => {
    return REASON_OF_INTEREST.map(reason => {
        return (
            <option key={`option-${reason}`} value={reason}>
                {reason}
            </option>
        );
    });
};

const ProfileSelect = () => {
    return PROFILES.map(profile => {
        return (
            <option key={`option-${profile.title}`} value={profile.title}>
                {profile.title} {profile.has_dimensions ? `**` : ``}
            </option>
        );
    });
};

const DimensionsSelector = ({ register, errors }) => (
    <div className="small select-wrapper mt-3">
        <label>
            Choose the option that best describes your building type (Optional)
        </label>
        <span className="select-wrapper-inner">
            <select
                id="building_type"
                name="building_type"
                ref={register({ required: false })}
                defaultValue={`DEFAULT`}
            >
                <option value="DEFAULT" disabled>
                    Choose the option that best describes your building type
                </option>
                {DIMENSIONS.map((dimension, i) => (
                    <optgroup key={`dimension-${i}`} label={dimension.title}>
                        {dimension.children.map((subdim, j) => {
                            if (subdim.children) {
                                return (
                                    <>
                                        <option disabled>{subdim.title}</option>
                                        {subdim.children.map((subchild, i) => (
                                            <option
                                                key={`subchild-${i}`}
                                                className="sub-child"
                                                value={subchild.code}
                                            >
                                                {subchild.title}
                                            </option>
                                        ))}
                                    </>
                                );
                            }
                            return (
                                <option
                                    key={`subdimension-${i}x${j}`}
                                    value={subdim.code}
                                >
                                    {subdim.title}
                                </option>
                            );
                        })}
                    </optgroup>
                ))}
            </select>
        </span>

        <span
            role="alert"
            className={`validation-error small ${
                errors[`building_type`] ? `show` : `hide`
            }`}
        >
            This field is required.
        </span>
    </div>
);

export default Dropdown;

Dropdown.propTypes = {
    register: PropTypes.func,
    errors: PropTypes.object,
    pardot: PropTypes.bool,
    blok: PropTypes.shape({
        name: PropTypes.string,
        list: PropTypes.object,
        label: PropTypes.string,
        special: PropTypes.string,
        required: PropTypes.bool,
    }).isRequired,
};
