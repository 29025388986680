import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import RichText from '../elements/RichText';
import { Link } from 'gatsby';
import { FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon } from 'react-share';
import Layout from './Layout';
import Image from '../elements/Image';
import MailChimpSubscribe from '../elements/MailChimpSubscribe';
import {format} from 'date-fns'

const Single = (props) => {

	const isStoryChief = props.blok.content_storychief && props.blok.content_storychief.length;
	const {blok} = props;
	const meta = {
		headline: blok.title,
		lang: `en`,
		title: `${blok.title} - SALTO KS`,
		description: blok.excerpt || `SALTO KS, SALTO Keys as a Service - Wireless Access Control For Your Business. Cloud-Based, Real-Time And On-The-Go Smart Lock Solution. Go Keyless Now!`,
		keywords: blok.keywords || `Access control, locks, wireless, cloud-based, keyless, entry, real-time`,
		schemaImg: blok.thumb[0].image
	};
	let schf_script = ``;
	let ampurl = '';
	if(isStoryChief && props.blok.script_storychief){
		let scriptStart = props.blok.script_storychief.indexOf(`<script>`);
		let scriptEnd = props.blok.script_storychief.indexOf(`</script>`);
		ampurl = props.blok.ampurl;
		schf_script = props.blok.script_storychief.slice(scriptStart + 8, scriptEnd);
	}

	let parsedDate = blok.date.replace(/ /g, `T`);

	let postDate = format(new Date(parsedDate), `do 'of' MMMM',' yyyy`);


	
	return (
		<Layout
			slug={props.slug}
			siblings={props.siblings}
			headerStyle='transparent'
			nav={props.nav}
			full={true}
			meta={meta}
		>
			{
				isStoryChief && props.blok.script_storychief &&
					<Helmet>
						{ampurl && <link rel="amphtml" content={ampurl} />}
						<script defer>
							{schf_script}
						</script>
					</Helmet>
			}
			<div className="site-content">
				<div className="post-detail-intro row mb-4">
					<div className="post-detail-intro-image overlay w-100">
						{
							blok.hero && isStoryChief ? <img src={blok.hero[0].image} /> : <Image blok={blok.hero[0]} />
						}
					</div>

					<div className="post-detail-body-content position-relative container mb-5">
						<div className="row">
							<div className="col-12 post-controls position-lg-absolute d-flex justify-content-between">
								<div className="content-back-button">
									<Link to="/blog-posts" className="info">
										<i className="fa fa-arrow-left"></i>Back
									</Link>
								</div>
								<div className="col-lg-1 justify-content-lg-start flex-lg-column content-share-buttons col-7 d-flex justify-content-end text-right initial">
									<FacebookShareButton
										// url={`https://saltoks.com/${props.slug}`}
									>
										<FacebookIcon size={35} round />
									</FacebookShareButton>
									<LinkedinShareButton
										// url={`https://saltoks.com/${props.slug}`}
									>
										<LinkedinIcon size={35} round />
									</LinkedinShareButton>
								</div>
							</div>

							<div className="content-body col-12 mx-auto mt-2 mt-md-5 col-lg-6 mt-lg-0">

								{blok.date && 
									<p className="xsmall category-label">
										Posted on {postDate}
									</p>
								}

								<h2 className="text-black mb-5">
									{blok.title}
								</h2>

								<RichText amp={meta.amp} blok={blok} />

								{blok.show_subscribe ? <MailChimpSubscribe /> : null }
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</Layout>
	);
};

Single.propTypes = {
	slug: PropTypes.string,
	siblings: PropTypes.object,
	metadata: PropTypes.shape({
		published: PropTypes.string
	}),
	blok: PropTypes.shape({
		header_style: PropTypes.string,
		content: PropTypes.object,
		title: PropTypes.string,
		_uid: PropTypes.string.isRequired,
		hero: PropTypes.array
	}).isRequired,
	nav: PropTypes.object
};

export default Single;