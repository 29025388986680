import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Image from '../elements/Image';
import { Link, useStaticQuery, graphql } from "gatsby";
import { useState, useEffect } from 'react';
import FuzzySearch from 'fuzzy-search';

const Stories = React.memo((props) => {

	const [nrOfPosts, setNrOfPosts] = useState(20);
	const [activeVertical, setactiveVertical] = useState(`all`);
	const [filteredStories, setFilteredStories] = useState([]);
	const [allVerticals, setAllVerticals] = useState([]);

	const { type, search, filter } = props.blok;

	const data = useStaticQuery(graphql`
		query StoryData {
			allStoryblokEntry(filter: {full_slug: {regex: "/stories\//"}, field_component: {ne: "page"}, lang: {eq:"default"}}, sort: {order: DESC, fields: field_date_string}) {
				edges {
					node {
						content
						first_published_at
						full_slug
						field_component
						uuid
					}
				}
			}
		}
	`);

	const filterBy = type === `blog` ? `category` : `vertical`;


	const allPosts = data.allStoryblokEntry.edges;

	useEffect(()=> {
		if(typeof window === `undefined` || typeof document === `undefined`){
			return;
		}
		let footer = document.querySelector(`footer.page-footer`);
		const FOOTER_OFFSET = footer.offsetHeight;
		window.onscroll = () => {
			if((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - FOOTER_OFFSET){
				getNextPosts();
			}
		};
	});

	const getNextPosts = () => {
		if(nrOfPosts < filteredStories.length){
			setNrOfPosts(nrOfPosts + 20);
		}
	};

	const parseVertical = vertical => {
		return vertical.toLowerCase();
	}

	useEffect(() => {
		const categories = [];
		
		// Default value
		categories.unshift({
			value: `all`,
			label: `Show All`
		});

		allPosts.map(post => {
			const content = 
				typeof post.node.content === `string` 
					? JSON.parse(post.node.content) 
					: post.node.content
				;
			const filter = content[filterBy];

			if(filter && !categories.find(({value}) => {
				// console.log(`${value} - ${filter}`);
				return value === parseVertical(filter);
			})){
				console.log(`adding ${filter}`)
				categories.push({ 
					value: parseVertical(filter),
					label: formatVertical(filter)
				});
			}
			return post.node.content = content;

		});

		let sortedCategories = categories.sort((a,b) => a.value.localeCompare(b.value));
		
		setAllVerticals(sortedCategories);
		setFilteredStories(allPosts);
	}, []);


	const searcher = new FuzzySearch(
		allPosts, 
		[`node.content.title`,`node.content.vertical`, `node.content.content`]
	);

	const doSearch = (e) => {
		const searchResult = searcher.search(e.target.value);
		setFilteredStories(searchResult);
	};


	const changeCategory = category => {
		if(category === `all`){
			setFilteredStories(allPosts);
			return;
		}
		setFilteredStories(allPosts.filter(post => {
			
			let toCompare = post.node.content.vertical ? post.node.content.vertical : post.node.content.category;
			return parseVertical(toCompare) === category;
		}));
	};

	const shouldRender = (postCategory, i) => {
		if(activeVertical === `all` || postCategory.length === 0) {
			return i < nrOfPosts;
		} else {
			return activeVertical.toLowerCase() === postCategory.toLowerCase() && i < nrOfPosts;
		}
	};


	return (
		<SbEditable content={props.blok}>
			<div className="row w-100">
				{search && 
					<div className="col-12 col-md-5 col-lg-3 mb-3">
						<div id="search">
							<input type="text" placeholder="Search" onChange={(e)=> doSearch(e)} className="search-input w-100" id="search-input"/>
						</div>
					</div>
				}
				{filter && allVerticals.length ?
					<div className="mr-0 ml-auto col-12 col-md-5 col-lg-3 d-flex justify-content-end align-items-center mb-3">
						<select 
							className="w-100 filter"
							name="Customer stories filter"
							onChange={(e)=>changeCategory(e.target.value)}
							defaultValue={`all`}
						>
							<option value="all">{allVerticals[0].label}</option>
							
							{allVerticals.map((option, i) => {
								return i !== 0 && <option key={`option-${i}`} value={option.value}>{option.label}</option>;
							})}
						</select>
					</div>
					:
					``
				}
			</div>
			<div className="row w-100">

				{filteredStories.map((post, i) => {
					if(shouldRender(post.node.content.category, i)){
						return post.node && post.node.full_slug.includes(`stories/`) && <StoryCard key={post.node.uuid} story={post}/>;
					}
				})}
			</div>
		</SbEditable>
	);
}, []);

export default Stories;


const showVertical = (content) => {
	return `${formatVertical(content.vertical)}${content.location && `, ${content.location}`}`;
}

const StoryCard = ({story}) => {
	const {content} = story.node;
	const thumbImage = content.thumb[0];
	thumbImage.dimensions = {
		width: 330
	};
	return (
		<div className="col-12 col-md-6 col-lg-4 d-flex">
			<div className="story-block w-100 d-flex flex-column">
				<div className="header">
					<h3 className="no-decoration m-0">
						{content.partner_name ? content.partner_name : content.title}
					</h3>
					<span className="small text-grey">
						{
						content.vertical 
							? showVertical(content)
							: `Coliving, Sydney`
						}
					</span>
				</div>
				<Link to={getUrl(story.node.full_slug)} className="image w-100">
					<Image blok={thumbImage} className="w-100" alt="" />
				</Link>
				<h6 className="story-title">
					{content.title}
				</h6>

				<Link to={getUrl(story.node.full_slug)} className="info">
					<i className="fa fa-arrow-right text-blue"></i>
								Read the story
				</Link>

			</div>
		</div>
	);
};

const getUrl = (path) => {
	if(path.charAt(0) !== `/`){
		return `/${path}`
	} 
	return path;
}

const formatVertical = title => {
	if(!title || !title.length) {
		return;
	}

	const titleArray = title.split(`-`);
	let finalTitle = ``;

	titleArray.forEach(word => {
		const formatted = word.charAt(0).toUpperCase() + word.substring(1);
		finalTitle = finalTitle.concat(formatted, ` `);
	});

	return finalTitle.slice(0, -1); // Remove the extra space in the end
};


StoryCard.propTypes = {
	story: PropTypes.shape({
		node: PropTypes.shape({
			first_published_at: PropTypes.string,
			full_slug: PropTypes.string,
			content: PropTypes.shape({
				partner_name: PropTypes.string,
				vertical: PropTypes.string,
				location: PropTypes.string,
				thumb: PropTypes.array,
				title: PropTypes.string
			})
		})
	})
};

Stories.propTypes = {
	blok: PropTypes.shape({
		card_section: PropTypes.arrayOf(
			PropTypes.shape({
				component: PropTypes.string.isRequired,
				_uid: PropTypes.string.isRequired
			})
		),
		type: PropTypes.string,
		search: PropTypes.bool,
		filter: PropTypes.bool
	}).isRequired
};
