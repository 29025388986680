import Page from './layout/Page';
import PageEndpoint from './layout/PageEndpoint';
import Single from './layout/Single';
import Row from './layout/Row';
import ExpandedRow from './layout/ExpandedRow';
import Column from './layout/Column';
import Form from './layout/Form';
import FormPardot from './layout/FormPardot';
import Posts from './ui/Posts';
import Stories from './ui/Stories';
import Events from './ui/Events';
import Footer from './layout/Footer';
import LeadsDatabase from './layout/LeadsDatabase';

import Image from './elements/Image';
import CarouselWrapper from './ui/CarouselWrapper';
import MainNav from './ui/MainNav';
import MainTitle from './elements/MainTitle';
import SecondaryTitle from './elements/SecondaryTitle';
import TertiaryTitle from './elements/TertiaryTitle';
import SubTitle from './elements/SubTitle';
import SmallTitle from './elements/SmallTitle';
import Paragraph from './elements/Paragraph';
import Features from './ui/Features';
import CustomLink from './elements/CustomLink';
import LinkWrapper from './elements/LinkWrapper';
import Button from './elements/Button';
import CollapseWrapper from './ui/CollapseWrapper';
import FilteredCards from './ui/FilteredCards';
import Card from './ui/Card';
import PartnerCard from './ui/PartnerCard';
import CardSection from './ui/CardSection';
import IconLine from './elements/IconLine';
import RichText from './elements/RichText';
import Table from './ui/Table';
import Box from './ui/Box';
import Brick from './ui/Brick';
import Tabs from './ui/Tabs';
import ColivingGraphic from './ui/ColivingGraphic';
import Div from './elements/Div';
import List from './elements/List';
import Quote from './elements/Quote';
import Modal from './ui/Modal';
import Jobs from './ui/Jobs';
import Video from './elements/Video';
import PlayButton from './elements/PlayButton';
import HorizontalScroll from './ui/HorizontalScroll';
import Select from './elements/Select';
import ComponentNotFound from './ComponentNotFound';
import ListItem from './elements/ListItem';
import TextInput from './elements/TextInput';
import Dropdown from './elements/Dropdown';
import PhoneField from './elements/PhoneInput';
import Endpoint from './ui/Endpoint';
import SecuritySlider from './ui/SecuritySlider';
import SecuritySlide from './ui/SecuritySlide';
import CommonQuestions from './ui/CommonQuestions';
import SupportSections from './ui/SupportSections';
import NewsletterArchive from './ui/NewsletterArchive';
import PageAnswer from './layout/PageAnswer';
import PageSupport from './layout/PageSupport';
import LatestPosts from './ui/LatestsPosts';
import PartnerBox from './elements/PartnerBox';
import DealersMap from './elements/DealersMap';
import Html from './elements/Html.js';

const ComponentList = {
	page: Page,
	page_endpoint: PageEndpoint,
	page_answer: PageAnswer,
	page_support: PageSupport,
	single: Single,
	single_story: Single,
	posts: Posts,
	stories: Stories,
	events: Events,
	navigation: MainNav,
	row: Row,
	expanded_row: ExpandedRow,
	column: Column,
	main_title: MainTitle,
	tertiary_title: TertiaryTitle,
	secondary_title: SecondaryTitle,
	sub_title: SubTitle,
	small_title: SmallTitle,
	paragraph: Paragraph,
	carousel: CarouselWrapper,
	image: Image,
	custom_link: CustomLink,
	link_wrapper: LinkWrapper,
	button: Button,
	features: Features,
	collapse: CollapseWrapper,
	filtered_cards: FilteredCards,
	card: Card,
	partner_card: PartnerCard,
	card_section: CardSection,
	icon_line: IconLine,
	rich_text: RichText,
	table: Table,
	list: List,
	list_item: ListItem,
	box: Box,
	div: Div,
	quote: Quote,
	coliving_graphic: ColivingGraphic,
	modal: Modal,
	video: Video,
	play_button: PlayButton,
	tabs: Tabs,
	horizontal_scroll: HorizontalScroll,
	jobs: Jobs,
	select: Select,
	dropdown: Dropdown,
	form: Form,
	form_pardot: FormPardot,
	text_input: TextInput,
	phone_input: PhoneField,
	endpoint: Endpoint,
	security_slider: SecuritySlider,
	security_slide: SecuritySlide,
	common_questions: CommonQuestions,
	support_sections: SupportSections,
	latest_posts: LatestPosts,
	partner_box: PartnerBox,
	footer_navigation: Footer,
	newsletter_archive: NewsletterArchive,
	dealers_map: DealersMap,
	leads_database: LeadsDatabase,
	brick: Brick,
	html: Html
};

const Components = (type) => {
	if (!type) {
		return ComponentNotFound;
	}
	const adjType = type.toLowerCase();
	if (typeof ComponentList[adjType] === `undefined`) {
		return ComponentNotFound;
	}
	return ComponentList[adjType];
};

export default Components;
