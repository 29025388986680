import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';

const SubTitle = (props) => {
	const fullClass = `${props.blok.classes} ${props.blok.color}`;
	return (
		<SbEditable content={props.blok}>
			<h3 className={fullClass}>{props.blok.sub_title}</h3>
		</SbEditable>
	);
};

export default SubTitle;

SubTitle.propTypes = {
	blok: PropTypes.shape({
		classes: PropTypes.string,
		sub_title: PropTypes.string.isRequired,
		color: PropTypes.string
	}).isRequired
};