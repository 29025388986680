import React from 'react';
import PropTypes from 'prop-types';
import Components from '../Components';
import Layout from './Layout';
import {Helmet} from 'react-helmet';
import { ImageHelper } from '../../utilities/helpers';

const Page = (props) => {

	const {
		seo_title,
		excerpt,
		keywords,
		finalised_translations
	} = props.blok;


	const meta = {
		headline: props.metadata.title,
		lang: props.lang && props.lang !== `default` ? props.lang : `en`,
		title: seo_title ? seo_title : `${props.metadata.title} - SALTO KS | Cloud-Based Smart Locks, Wireless Access Control`,
		description: excerpt || `SALTO KS, SALTO Keys as a Service - Wireless Access Control For Your Business. Cloud-Based, Real-Time And On-The-Go Smart Lock Solution. Go Keyless Now!`,
		keywords: keywords || `Access control, locks, wireless, cloud-based, keyless, entry, real-time`,
		full_link: `https://saltoks.com/${props.slug}`,
		schema: [
			{
				'@type': `WebSite`,
				'@id': `https://saltoks.com/#website`,
				'url': `https://saltoks.com/${props.slug}`,
				'name': `SALTO KS`,
			},
			{
				'@type': `Organization`,
				'@id': `https://saltoks.com/#organization`,
				'url': `https://saltoks.com/${props.slug}`,
				'name': `SALTO KS`,
				'sameAs': `https://www.facebook.com/SALTO-Systems-620460885051552/`,
				'logo': ImageHelper.generateUrl(`//a.storyblok.com/f/72378/184x56/a01da24387/logo-color.png`)
			}
		],
		other: {
			...props.metadata
		}
	};

	return (
		<Layout
			slug={props.slug}
			siblings={props.siblings}
			headerStyle={props.blok.header_style}
			nav={{
				...props.nav,
				finalised_translations
			}}
			layout={props.blok.layout}
			meta={meta}
		>
			{
				props.blok.hero &&
				props.blok.hero.show &&
				<div className={`hero ${props.blok.hero.heroClass}`} />
			}
			{
				props.blok.body &&
					props.blok.body.map((blok) => {
						return React.createElement(
							Components(blok.component),
							{ key: blok._uid, blok: blok }
						);
					})
			}
		</Layout>
	);
};

Page.propTypes = {
	slug: PropTypes.string,
	siblings: PropTypes.object,
	blok: PropTypes.shape({
		body: PropTypes.array.isRequired,
		header_style: PropTypes.string,
		layout: PropTypes.string,
		_uid: PropTypes.string.isRequired,
		hero: PropTypes.shape({
			show: PropTypes.bool,
			heroClass: PropTypes.string
		})
	}).isRequired,
	nav: PropTypes.object
};

export default Page;