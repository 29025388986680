/*
* Example string: https://a.storyblok.com/f/39898/3310x2192/e4ec08624e/demo-image.jpeg
* The structure of the url is always the same : domain/f/spaceID/dimensions/id/file-name.format
* So we split the link at the '/' character, and the 5th item holds the dimensions of the file
* To get the format, we take everything after the last dot (.) in the url
*/

const getOriginalDimensions = path => {
	let sizeString = path.split(`/`)[5];
	const dimensions = sizeString.split(`x`);

	return {
		width: dimensions[0],
		ratio: dimensions[0] / dimensions[1]
	};
};


const getFormat = path => {
	const dotPosition = path.lastIndexOf(`.`);
	return path.slice(dotPosition + 1, path.length);
};

const generateUrl = (path, dimension, webp, byHeight) => {

	if(!path.includes(`storyblok`)){
		return path;
	}
	let options = ``;

	if(dimension){
		options = byHeight ? `0x${dimension}` : `${dimension}x0`;
	}

	if(webp){
		options = `${options}/filters:format(webp)`;
	}

	return path + `${options ? `/m/` : `` }` + options;
};

export {
	getOriginalDimensions,
	generateUrl,
	getFormat
};
