import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import {Link, useStaticQuery, graphql} from 'gatsby';
import Image from '../elements/Image';

const LatestPosts = (props) => {

	const {
		categories,
		nr_of_posts,
		specific_posts
	} = props.blok;


	const [posts, setPosts] = useState([]);

	const postsData = useStaticQuery(graphql`
		query postsQuery {
			allStoryblokEntry(filter: {field_component: {eq: "single"}, lang: {eq: "default"}}, sort: {order: DESC, fields: field_date_string}) {
				edges {
					node {
						content
						full_slug
						id
						uuid
						first_published_at
						field_component
						lang
					}
				}
			}
		}
	`);

	const allPosts = postsData.allStoryblokEntry.edges;
	
	let hasCategories = false;
	categories.forEach(category => {
		if(Object.keys(category).length > 0){
			hasCategories = true;
		}
	})

	useEffect(() => {
		let filteredPosts = [];

		if(specific_posts && specific_posts.length){
			filteredPosts = allPosts.filter((post) => {
				if(post.node.field_component !== `single`){
					return false;
				}
				return specific_posts.includes(post.node.uuid);
			}).slice(0, nr_of_posts);
		} else {
			let counter = 0;
			filteredPosts = allPosts.filter((post, i) => {
				if(post.node.field_component !== `single`){
					return false;
				}
				const content = JSON.parse(post.node.content);
				if(!hasCategories) return counter++ < nr_of_posts;
				return categories.includes(content.category) && counter++ < nr_of_posts;
			});
		}
		setPosts([...filteredPosts]);
	}, []);

	return (
		<SbEditable content={props.blok}>
			<div className="row">
				{posts.length && posts.map((post, i) => {
					if( i >= posts.length) {
						return null;
					}
					let content = JSON.parse(post.node.content)
					const singlePost = {
						slug: post.node.full_slug,
						content: content,
						date: content.date
					};
					return <SinglePost key={post.node.id} post={singlePost} />;
				})}
				
			</div>
		</SbEditable>
	);
};

export default LatestPosts;

const SinglePost = ({post}) => {
	const date = new Date(post.date).toDateString();
	const thumbImage = post.content.thumb[0];
	
	thumbImage.dimensions = {
		width: 330
	};

	return (
		<div className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column post-container mt-3">
			<div className="post-image-container">
				<Link to={getUrl(post.slug)}>
					<Image blok={thumbImage}/>
				</Link>
			</div>
			<div className="post-text-container">
				<p className="small mb-0">
					Posted on {date}
				</p>
				<h2>{post.content.title}</h2>
				<Link to={getUrl(post.slug)} className="info">
					<i className="fa fa-arrow-right text-blue"></i>
					Read more
				</Link>
			</div>
		</div>
	);
};


const getUrl = (path) => {
	if(path.charAt(0) !== `/`){
		return `/${path}`
	} 
	return path;
}


SinglePost.propTypes = {
	post: PropTypes.shape({	
		content: PropTypes.shape({
			thumb: PropTypes.array,
			category: PropTypes.string,
			title: PropTypes.string
		}),
		slug: PropTypes.string,
		date: PropTypes.string
	}).isRequired
};

LatestPosts.propTypes = {
	blok: PropTypes.shape({
		categories: PropTypes.array,
		nr_of_posts: PropTypes.string,
		specific_posts: PropTypes.array
	}).isRequired
};
