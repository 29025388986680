import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';

const CustomLink = (props) => {

	const { link, icon, text, classes } = props.blok;

	return (
		<SbEditable content={props.blok}>

			{link.linktype === `url`
				? <a href={link.url} title={text} className={`${classes} info mr-3 d-inline-block`} target="_blank" rel="noreferrer noopener" title="Go to section" >
					{icon.icon.length ? <Icon icon={icon} /> : null}
					{text}
				</a>
				: <Link to={getUrl(link.cached_url)}  title={text} className={`${classes} d-block info mr-3 mb-2`}>
					{icon.icon.length ? <Icon icon={icon} /> : null}
					{text}
				</Link>
			}

		</SbEditable>
	);
};

const getUrl = (path) => {
	if(path.charAt(0) !== `/`){
		return `/${path}`
	} 
	return path;
}


const Icon = ({ icon }) => {
	if (!icon) {
		return false;
	}
	return <i className={`fa ${icon.icon} text-blue mr-2`}></i>;
};

Icon.propTypes = {
	icon: PropTypes.shape({
		icon: PropTypes.string
	})
};

export default CustomLink;

CustomLink.propTypes = {
	blok: PropTypes.shape({
		link: PropTypes.shape({
			cached_url: PropTypes.string,
			linktype: PropTypes.string,
			url: PropTypes.string
		}),
		text: PropTypes.string,
		classes: PropTypes.string,
		icon: PropTypes.object
	}).isRequired
};
