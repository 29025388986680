import React from 'react';
import PropTypes from 'prop-types';
import Layout from './Layout';
import RichText from '../elements/RichText';
import { Link } from 'gatsby';
import SbEditable from 'storyblok-react';
import Export from '../ui/Export';
const StoryblokClient = require(`storyblok-js-client`);
const Storyblok = new StoryblokClient({});

const PageAnswer = (props) => {

	// Need to resolve the richtext content as string, then remove the html tags
	let answer ='';
	if(props.blok.content){
		answer = Storyblok.richTextResolver.render(props.blok.content);
	} 
	const parsedAnswer = answer && answer.replace(/(<([^>]+)>)/ig,``);

	const meta = {
		headline: props.blok.title,
		lang: `en`,
		title: `${props.blok.title} - SALTO KS Support`,
		description: parsedAnswer || `SALTO KS, SALTO Keys as a Service - Wireless Access Control For Your Business. Cloud-Based, Real-Time And On-The-Go Smart Lock Solution. Go Keyless Now!`,
		keywords: props.blok.keywords || `Access control, locks, wireless, cloud-based, keyless, entry, real-time`,
		full_link: props.slug,
		other: { ...props.metadata },
		schema: [
			{
				'@type': `FAQPage`,
				'mainEntity': {
					'@type': `Question`,
					'name': props.blok.title,
					'acceptedAnswer': {
						'@type': `Answer`,
						'text': parsedAnswer
					}
				}
			}
		]
	};

	return (

		<Layout
			slug={props.slug}
			siblings={props.siblings}
			headerStyle="default"
			nav={props.nav}
			meta={meta}
		>
			<SbEditable content={props.blok}>
				<div className="row mt-5">
					<div className="col-12 d-flex align-items-center col-lg-3">
						<Link to="/support" className="info">
							<i className="fa fa-arrow-left"></i>
							Back
						</Link>
					</div>

					<div className="col-12 col-lg-9">
						<h2 className="m-0">{props.blok.title}</h2>
					</div>
					<div className="col-12 col-lg-9 offset-lg-3 mt-4">
						<RichText blok={{ content: props.blok.content }} />
					</div>
					<div className="col-12 col-lg-9 offset-lg-3 mt-4">
						<Export content={{title: props.blok.title, body:answer}}>
							<button className="clear info pointer">
							<i className="fa fa-print mr-2"></i>Print this answer</button>
						</Export>
					</div>
				</div>
			</SbEditable>
		</Layout>
	);
};

PageAnswer.propTypes = {
	slug: PropTypes.string,
	siblings: PropTypes.object,
	blok: PropTypes.shape({
		body: PropTypes.array.isRequired,
		_uid: PropTypes.string.isRequired,
		title: PropTypes.string,
		content: PropTypes.object
	}).isRequired,
	nav: PropTypes.object
};

export default PageAnswer;