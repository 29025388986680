import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Image from './Image';

const PartnerBox = (props) => {
	const {
		link,
		image,
		vertical
	} = props.blok;
	return (
		<SbEditable content={props.blok}>
			<div className="col">
				<a 
					target="_blank" 
					rel="noopener noreferrer" 
					href={link.cached_url} 
					className="d-block inner-wrapper"
				>
					<div className="grid-image">
						<Image blok={image[0]}/>
					</div>
					{vertical && <span className="title text-uppercase">{vertical}</span>}
				</a>
			</div>
		</SbEditable>
	);
};

export default PartnerBox;

PartnerBox.propTypes = {
	blok: PropTypes.shape({
		link: PropTypes.shape({
			cached_url: PropTypes.string
		}),
		image: PropTypes.array,
		vertical: PropTypes.string
	}).isRequired
};