import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Components from '../Components';

const List = (props) => {
	return (
		<SbEditable content={props.blok}>
			<ul className="list">
				{props.blok.items.map(item => {
					return React.createElement(
						Components(item.component), 
						{
							key: item._uid, 
							blok: item
						}
					);
				})}
			</ul>
		</SbEditable>
	);
};

export default List;

List.propTypes = {
	blok: PropTypes.shape({
		items: PropTypes.arrayOf(
			PropTypes.shape()
		)
	}).isRequired
};