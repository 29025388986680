import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';

const Select = (props) => {

	const { blok, register, errors } = props;

	const {
		select_input,
		name,
		label,
		required
	} = blok;

	return (
		<SbEditable content={props.blok}>
			<div className="small">
				<label>
					{label} {required ? `*` : `(Optional)`}
				</label>
				<span
					role="alert"
					className={
						`validation-error small ${errors[name] ? `show` : `hide`}`
					}>
					This field is required.
				</span>
				{select_input.options && select_input.options.map((option, i) => {
					if (typeof option === `string`) {
						return (
							<div key={`option-${i}`} className="custom-radio">
								<input type="radio" id={`${name}-${i}`} name={name} ref={register({ required: true })} value={option} />
								<label htmlFor={`${name}-${i}`}>{option}</label>
							</div>
						);
					}

				})}

			</div>
		</SbEditable>
	);
};

export default Select;

Select.propTypes = {
	register: PropTypes.func,
	errors: PropTypes.object,
	blok: PropTypes.shape({
		select_input: PropTypes.object,
		name: PropTypes.string,
		label: PropTypes.string,
		required: PropTypes.bool
	})
};