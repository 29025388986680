import React, {useState} from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';

import Components from '../Components';

const FilteredCards = (props) => {
	const { description, cards } = props.blok;
	const [ activeVertical, setVertical ] = useState(`all`);

	const dropdown = () => {
		const verticals = [];
		
		cards.forEach(card => {

			const vertical = card.modal_trigger ? card.modal_trigger[0].vertical : card.vertical;
			if(verticals.find(({value}) => value === vertical.toLowerCase())){
				return;
			}

			verticals.push({ 
				value: vertical.toLowerCase(),
				label: vertical
			});
		});

		// The default value
		verticals.unshift({
			value: `all`,
			label: `All ${props.blok.type}`
		});

		
		// return <Select onChange={(v)=>setVertical(v.value)} defaultValue={verticals[0]} options={verticals} />;


		return (
			<select 
				name="Cards filter"
				onChange={(e)=>setVertical(e.target.value)}
				defaultValue={`all`}
			>
				<option value="all">{verticals[0].label}</option>
				
				{verticals.map((option, i) => {
					return i !== 0 && <option key={`option-${i}`} value={option.value}>{option.label}</option>;
				})}
			</select>
		);
	};

	const isInVertical = card => {
		const vertical = card.modal_trigger ? card.modal_trigger[0].vertical : card.vertical;
		return activeVertical === vertical.toLowerCase();
	};


	return (
		<SbEditable content={props.blok}>

			{ description && 
				<div className="col-lg-6 col-sm-12 offset-lg-1">

					{ description.map(item => {
						return React.createElement(
							Components(item.component), 
							{
								key: item._uid, 
								blok: item
							}
						);
					})
					}
				</div>
			}
			<div className="col-lg-3 col-sm-12 mt-5 mt-lg-0 d-flex justify-content-lg-end align-items-end">
				<div className="dropdown w-100">
					{dropdown()}
				</div>
			</div>
			<div 
				className="col-12 col-lg-9 offset-lg-1 mt-5"
			>
				<div className="row">
					{ cards && cards.map(card => {
						const show = 
							activeVertical === `all` 
							|| isInVertical(card)
						;

						if(show){
							return (
								<div className="col-12 col-md-6 col-lg-4 mb-4" key={card._uid}>
									{
										React.createElement(
											Components(card.component), 
											{
												key: card._uid, 
												blok: card
											}
										)
									}
								</div>
							);
						}
						
					})}
						
				</div>
				
			</div>

		</SbEditable>
	);
};

export default FilteredCards;

FilteredCards.propTypes = {
	blok: PropTypes.shape({
		description: PropTypes.arrayOf(
			PropTypes.shape({
				component: PropTypes.string,
				_uid: PropTypes.string
			})
		),
		cards: PropTypes.arrayOf(
			PropTypes.shape({
				_uid: PropTypes.string
			})
		),
		type: PropTypes.string
	}).isRequired
};

