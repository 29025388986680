import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Components from '../Components';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import {navigate} from 'gatsby';
import RichText from '../elements/RichText';

const Form = (props) => {
	const {
		left_column,
		right_column,
		left_title,
		left_description,
		right_title,
		right_description,
		action,
		download_url,
		button_text,
		success_message,
		error_message
	} = props.blok;


	const methods = useForm();
	const { handleSubmit } = methods;
	const [submitStatus, setSubmitStatus] = useState({ success: null, message: ``, show: false });
	const [disableSend, setDisableSend] = useState(false);
	const [loading, setLoading] = useState(false);
	const [source, setSource] = useState(``);

	useEffect(()=> {
		try {
			const globalDocument = typeof document == `undefined` ? null : document;
			if(globalDocument && globalDocument.cookie.length){
				const cookie = globalDocument.cookie.replace(/(?:(?:^|.*;\s*)source\s*\=\s*([^;]*).*$)|^.*$/, `$1`);
				if(cookie.length){
					setSource(cookie);
				}
			}
		} catch(e) {
			console.log(e);
		}
	}, []);

	const onSubmit = async data => {
		setDisableSend(true);
		setLoading(true);
		if(source.length){
			data.utm_source = source;
		}

		let result;
		let success = false;

		// return;
		try {
			result = await axios({
				method: `POST`,
				url: `.netlify/functions/zoho-lead`,
				data: {
					type: action,
					info: data,
					sendToZoho: false
				},
				headers: {
					'content-type': `application/json`
				}
			});
		} catch(e){
			console.log(e);	
		}

		success = result && result.status === 200;

		setLoading(false);
		if (!success) {
			setSubmitStatus({ success, message: error_message, show: true });
			setDisableSend(false); // So that the user can try again

			return;
		} else {
			if(download_url){
				location.href = download_url;
			}
		}

	}

	return (
		<SbEditable content={props.blok}>
			<form className="w-100 form-wrapper wpcf7" onSubmit={handleSubmit(onSubmit)}>
				<div className="row mx-0">
					<div className="col-12 col-md-6 pr-5 pl-0">
						{left_title &&
							<h4 className="mt-5 text-uppercase">
								{left_title}
							</h4>
						}
						{left_description &&
							<p className="sub-title">
								{left_description}
							</p>
						}
						{left_column.map(item => {
							return (
								<div key={item._uid} className="input-wrapper">
									{
										React.createElement(
											Components(item.component),
											{
												key: item._uid,
												blok: item,
												register: methods.register,
												errors: methods.errors,
												setValue: methods.setValue
											}
										)
									}
								</div>
							);
						})}
					</div>

					<div className="col-12 col-md-6 pr-5 pl-0">
						{right_title &&
							<h4 className="mt-5 text-uppercase">
								{right_title}
							</h4>
						}
						{right_description &&
							<p className="sub-title">
								{right_description}
							</p>
						}
						{right_column.map(item => {
							return (
								<div key={item._uid} className="input-wrapper">
									{
										React.createElement(
											Components(item.component),
											{
												key: item._uid,
												blok: item,
												register: methods.register,
												errors: methods.errors,
												setValue: methods.setValue
											}
										)
									}
								</div>
							);
						})}

						<div className="input-wrapper">
							<div className="tos-text">
								<input 
									type="checkbox"
									id="tos"
									name="tos"
									value="tos"
									ref={methods.register({ required: true })}
								/>
								<label htmlFor="tos">
									<RichText blok={{content: props.blok.terms_and_conditions}} />
								</label>
							</div>
							<span 
								role="alert" 
								className={
									`validation-error small ${methods.errors[`tos`] ? `show` : `hide`}`
								}>
									This field is required.
							</span>
						</div>

						<button className="btn w-100 btn-primary" disabled={disableSend} type="submit">
							{loading ? <img className="spin" src="https://img2.storyblok.com/f/72378/x/7d3d33acf2/rolling-1s-200px.svg" width="24px" /> : 
								<>
									{button_text || `Submit`}
								</>
							}
						</button>

						{submitStatus.show && 
							<span className="d-block mt-4">
								{submitStatus.success ? <i className="fa fa-check mr-2" style={{color: `green`}}></i> : <i style={{color: `red`}} className="mr-2 fa fa-exclamation-triangle"></i>}
								{submitStatus.message}
							</span>
						}
					</div>

				</div>

			</form>
		</SbEditable>
	);
};

export default Form;

Form.propTypes = {
	blok: PropTypes.shape({
		classes: PropTypes.string,
		left_column: PropTypes.array,
		right_column: PropTypes.array,
		left_title: PropTypes.string,
		left_description: PropTypes.string,
		right_title: PropTypes.string,
		right_description: PropTypes.string,
		action: PropTypes.string,
		download_url: PropTypes.string,
		button_text: PropTypes.string,
		success_message: PropTypes.string,
		error_message: PropTypes.string
	})
};
