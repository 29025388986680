import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const DevMenu = ({ nav, current }) => {
	const menu = JSON.parse(nav.content);
	return (
		<>
			{menu.items.map(section => {
				return (
					<nav key={section._uid} className="developers-menu border-none">
						<h4 className="dev-gray-headline">
							{section.title}
						</h4>
						<ul className={`nav navbar-nav ${section.numbered ? `list-decimal` : ``}`}>
							{section.items.map(item => <MenuItem key={item._uid} item={item} current={current} />)}
						</ul>
					</nav>
				);
			})}
		</>
	);
};

DevMenu.propTypes = {
	nav: PropTypes.object,
	current: PropTypes.string
};

const MenuItem = ({ item, current }) => {
	const [expand, setExpand] = useState(false);

	const getUrl = (path) => {
		if(path.charAt(0) !== `/`){
			return `/${path}`
		} 
		return path;
	}

	useEffect(()=> {
		if(
			item.link.cached_url === current
			|| item.children.find(child => child.link.cached_url === current)
		) {
			setExpand(true);
		}
	}, []);
	
	return (
		<li className={`menu-item ${item.link.cached_url === current && `current-menu-item`}`}>

			{item.link.cached_url.length
				? <Link to={getUrl(item.link.cached_url)}>{item.title}</Link>
				: <a onClick={() => setExpand(!expand)}>{item.title}</a>
			}

			{item.children.length ?

				<>
					<button onClick={() => setExpand(!expand)} className="expand unstyled-btn">
						{expand ? `-` : `+`}
					</button>
					{expand &&
						<ul className="sub-menu">
							{item.children.map(child => {
								if(child.link === current){
									setExpand(true);
								}
								return <MenuItem key={child._uid} item={child} current={current} />;
							})}
						</ul>
					}
				</>
				: null
			}
		</li>
	);
};

MenuItem.propTypes = {
	item: PropTypes.shape({
		title: PropTypes.string,
		link: PropTypes.object,
		children: PropTypes.array
	}),
	current: PropTypes.string
};


export default DevMenu;
