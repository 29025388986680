import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import RichText from '../elements/RichText';
import Components from '../Components.js'

const Endpoint = (props) => {

	return (
		<SbEditable content={props.blok}>
			<p className={`${props.blok.classes} ${props.blok.big && `intro`}`} >
				{props.blok.paragraph}
			</p>

			<div className="row h-100 flex-lg-row">
				<div className="col-lg-4 content">
					{props.blok.description && props.blok.description.map(item => {
						return React.createElement(
							Components(item.component),
							{
								key: item._uid,
								blok: item,
							}
						);
					})}
				</div>
			</div>
		</SbEditable>
	);
};

export default Endpoint;

Endpoint.propTypes = {
	blok: PropTypes.shape({
		classes: PropTypes.string,
		big: PropTypes.bool
	})
};