import React from 'react';
import PropTypes from 'prop-types';

const ComponentNotFound = (props) => {
	console.warn(`Component '${props.blok.component}' not found!`);
	return null;
};

ComponentNotFound.propTypes = {
	blok: PropTypes.shape({
		component: PropTypes.string.isRequired
	})
};

export default ComponentNotFound;