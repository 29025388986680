import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';

const SecondaryTitle = (props) => {
	const fullClass = `${props.blok.classes} ${props.blok.color}`;
	return (
		<SbEditable content={props.blok}>
			<h2 className={fullClass}>{props.blok.secondary_title}</h2>
		</SbEditable>
	);
};

export default SecondaryTitle;

SecondaryTitle.propTypes = {
	blok: PropTypes.shape({
		classes: PropTypes.string,
		secondary_title: PropTypes.string.isRequired,
		color: PropTypes.string
	}).isRequired
};