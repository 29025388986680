import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';

const Paragraph = (props) => {
	return (
		<SbEditable content={props.blok}>
			<p className={`${props.blok.classes} ${props.blok.big && `intro`}`} >
				{props.blok.paragraph}
			</p>
		</SbEditable>
	);
};

export default Paragraph;

Paragraph.propTypes = {
	blok: PropTypes.shape({
		paragraph: PropTypes.string.isRequired,
		classes: PropTypes.string,
		big: PropTypes.bool
	})
};