import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Components from '../Components';

const Table = (props) => {
	const fullClass = `${props.blok.classes} w-100 table`;
	return (
		<SbEditable content={props.blok}>
			<table className={fullClass}>
				<tbody>
					{
						props.blok.rows && props.blok.rows.map((row, i) => {
							return( <tr key={`row-${i}`}>
								{ row.items.map((item, i) => {
									return (
										<td key={`col-${i}`}>
											{
												React.createElement(
													Components(item.component), 
													{
														key: item._uid, 
														blok: item
													}
												)
											}
										</td>
									);
								})}
							</tr>);
						})
					}
				</tbody>
			</table>
		</SbEditable>
	);
};

export default Table;

Table.propTypes = {
	blok: PropTypes.shape({
		classes: PropTypes.string,
		rows: PropTypes.arrayOf(
			PropTypes.object
		)
	}).isRequired
};