import React, { Component, useState, useEffect } from 'react';
import { GoogleMap, LoadScript, MarkerClusterer, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import { DEALERS } from '../../utilities/dealersParsed';
import DealersSearch from './DealersSearch';

const DealersMap = (props) => {

	const [activeDealer, setActiveDealer] = useState(null);
	const [mapPosition, setMapPosition] = useState({ lat: 52.379189, lng: 4.899431 });
	const [zoom, setZoom] = useState(10);

	const mapContainerStyle = {
		height: `450px`,
		width: `auto`
	};

	useEffect(()=> {
		// Try HTML5 geolocation.
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(function(position) {
				var pos = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};
				setMapPosition(pos);
			}, function() {
				console.warn(`Couldn't get location`);
			});
		} else {
			// Browser doesn't support Geolocation
			console.warn(`Couldn't get location - not suppoted`);
		}	
	}, []);

	const showDealer = (dealer) => {
		setActiveDealer(dealer);
		setMapPosition(parsePosition(dealer.position));
	};

	const closeDealerInfo = () => {
		setActiveDealer(null);
	};

	const goToPosition = (coords) => {
		
		const newDealer = DEALERS.find(dealer => {
			return dealer.position.lat == coords.lat && dealer.position.lng == coords.lng;
		});
		
		setActiveDealer(newDealer);
		setMapPosition(parsePosition(coords));
		setZoom(15);
	};

	const parsePosition = (position) => {
		if(!position.lat) return;
		return {
			lat: parseFloat(position.lat.toString().replace(/(,[^,]*),/g, `$1`).replace(`,`, `.`)),
			lng: parseFloat(position.lng.toString().replace(/(,[^,]*),/g, `$1`).replace(`,`, `.`))
		};
	};

	return (
		<SbEditable content={props.blok}>
			<div className="map-section">
				<h3>{props.blok.title}</h3>
				<div className="map-wrapper position-relative">
					{activeDealer && <InfoBox dealer={activeDealer} close={() => closeDealerInfo()} />}
					<DealersSearch dealersList={DEALERS} goToDealer={(coords) => goToPosition(coords)}/>
					<LoadScript
						id="script-loader"
						googleMapsApiKey={props.blok.api_key}
					>
						<GoogleMap
							mapContainerStyle={mapContainerStyle}
							id='dealers-map'
							zoom={zoom}
							center={mapPosition}
							options={{
								mapTypeControl: false
							}}
						>
							<MarkerClusterer key={`idk`}>
								{clusterer =>
									DEALERS.map(dealer => {
										const position = parsePosition(dealer.position);
										return <Marker
											onClick={() => showDealer(dealer)}
											key={dealer._uid}
											position={position}
											clusterer={clusterer}
											imagePath="https://a.storyblok.com/f/72378/56x55/a58ac02c66/cluster_icon.png"
											icon="https://a.storyblok.com/f/72378/40x52/48861845af/pinpoint-inactive.png"
										/>;
									})
								}
							</MarkerClusterer>
						</GoogleMap>
					</LoadScript>
				</div>
			</div>
		</SbEditable>
	);
};

const InfoBox = ({ dealer, close }) => (
	<div className="info-box">
		<button className="close" onClick={() => close()} type="button" aria-label="Close">
			<span aria-hidden="true">×</span>
		</button>
		<h4 className="company-title">{dealer.title}</h4>
		{dealer.address && <p className="small company-location">{dealer.address}</p>}
		<p className="small company-location">{dealer.zip}, {dealer.city ? `${dealer.city},` : ``} {dealer.country}</p>
		{dealer.phone && <p className="small company-phone">{dealer.phone}</p>}
	</div>
);

export default DealersMap;

DealersMap.propTypes = {
	blok: PropTypes.shape({
		title: PropTypes.string,
		api_key: PropTypes.string,
		dealers: PropTypes.arrayOf(
			PropTypes.shape({
				latitude: PropTypes.string,
				longitude: PropTypes.string,
				name: PropTypes.string,
				address: PropTypes.string,
				phone: PropTypes.string
			})
		)
	})
};

InfoBox.propTypes = {
	dealer: PropTypes.obj,
	close: PropTypes.func
};