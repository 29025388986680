import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';

const MainTitle = (props) => (
	<SbEditable content={props.blok}>
		<h1 className={props.blok.classes}>{props.blok.main_title}</h1>
	</SbEditable>
);

export default MainTitle;

MainTitle.propTypes = {
	blok: PropTypes.shape({
		classes: PropTypes.string,
		main_title: PropTypes.string.isRequired
	}).isRequired
};