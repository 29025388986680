import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';

const ListItem = (props) => {
	const {
		classes,
		icon,
		text
	} = props.blok;
	return (
		<SbEditable content={props.blok}>
			<li className={classes}>
				{icon && icon.icon && <i className={`mr-1 fa ${icon.icon}`}></i>}
				{text}
			</li>
		</SbEditable>
	);
};

export default ListItem;

ListItem.propTypes = {
	blok: PropTypes.shape({
		classes: PropTypes.string,
		icon: PropTypes.object,
		text: PropTypes.string
	}).isRequired
};