import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Components from '../Components';

const Column = (props) => {

	const sizes = props.blok.column_size;

	let cols = ``;
	if(sizes && Object.prototype.hasOwnProperty.call(sizes, `items`)){
		Object.keys(sizes.items).map(size => {
			if(`_uid` !== size && sizes.items[size]){
				let formatCol = ``;
				if(size === `default`){
					formatCol = `col-${sizes.items[size]}`;
				} else {
					formatCol = `col-${size}-${sizes.items[size]}`;
				}
				cols = `${cols} ${formatCol}`;
			}
		});
	} 

	return (
		<SbEditable content={props.blok}>
			<div className={`${cols} ${props.blok.classes}`} id={props.blok.reference}>
				{
					props.blok.items.map(item => {
						return React.createElement(
							Components(item.component), 
							{
								key: item._uid, 
								blok: item
							}
						);
					})
				}
			</div>
		</SbEditable>
	);
};

export default Column;

Column.propTypes = {
	blok: PropTypes.shape({
		classes: PropTypes.string,
		items: PropTypes.arrayOf(
			PropTypes.object
		),
		column_size: PropTypes.shape({
			items: PropTypes.object
		}),
		reference: PropTypes.string
	})
};