import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Image from '../elements/Image';
import Loader from '../elements/Loader';
import { Link, useStaticQuery, graphql } from "gatsby";
import { useState, useEffect, useRef } from 'react';
import FuzzySearch from 'fuzzy-search';
import axios from 'axios';
const _ =  require('lodash');
import { format } from 'date-fns'

const Posts = (props) => {

	const [nrOfPosts, setNrOfPosts] = useState(18);
	const [activeCategory, setActiveCategory] = useState(`all`);
	const [isLoading, setIsLoading] = useState(false);
	const currentPage = useRef(1);
	const hasMore = useRef(true)
	const searchTerm = useRef(``);

	const { type, search, filter } = props.blok;

	const data = useStaticQuery(graphql`
		query PostsData {
			allStoryblokEntry(filter: {full_slug: {regex: "/blog\//"}, lang: {eq:"default"}}, sort: {order: DESC, fields: field_date_string}, limit: 18) {
				edges {
					node {
						name
						content
						first_published_at
						full_slug
						field_component
						field_date_string
						uuid
					}
				}
			}
			allStoryblokDatasource(filter: {data_source: {eq: "blog-post-categories"}}, sort: {fields: value}) {
				edges {
					node {
						data_source
						value
						name
					}
				}
			}
		}
	`);

	const filterBy = `category`;
	const allPosts = data.allStoryblokEntry.edges;
	let allCategories = data.allStoryblokDatasource.edges;

	const [filteredPosts, setFilteredPosts] = useState([...allPosts]);

	const getNewPosts = async (replace, page, category) => {
		setIsLoading(true);
		if(!category){
			category = activeCategory;
		}
		const options = {
			method: `GET`,
			url: `https://api.storyblok.com/v1/cdn/stories`,
			params: {
				starts_with: `blog/`,
				token: 	`rTEXKYwPJo5RgIS25Vl9qwtt`, // Public, read-only token
				per_page: nrOfPosts,
				page: currentPage.current,
				sort_by: `content.date:desc`,
				search_term: searchTerm.current || ``
			}
		};

		if(category && category !== `all` && category !== `recap`){
			options.params[`filter_query[category][in]`] = category;
		}

		if(category === `recap`){
			options.params[`by_slugs`] = `*${category}-20*`
		}

		let result;
	
		try {
			result = await axios(options);
			if(!result.data.stories.length) {
				hasMore.current = false;
				// return; 
			}

			let parsedPosts = parsePosts(result.data.stories);
			if(replace){
				setFilteredPosts(parsedPosts)
			} else {
				setFilteredPosts([...filteredPosts, ...parsedPosts])
			}

			if(result.headers.total <= filteredPosts.length){
				hasMore.current = false;
			} else {
				hasMore.current = true;
			}


		} catch(e){
			console.error(e);
		}

		setIsLoading(false);
	
	}

	const parsePosts = stories => {	
		let parsed = [];
		if(!stories.length){
			return parsed;
		}
		parsed = stories.map(story => {
			let newStory = {...story}
			if(story.full_slug === `blog/`){
				return null;
			}
			if(story.full_slug.includes(`blog/recap-`)){	
				newStory.content = {
					...prepareRecap(story)
				};
			}
			return {
				node: {
					...newStory
				}
			}
		})

		return parsed;
	}
	useEffect(()=> {
		if(typeof window === `undefined` || typeof document === `undefined`){
			return;
		}
		let footer = document.querySelector(`footer.page-footer`);
		const FOOTER_START = footer.offsetTop;
		
		

		window.onscroll = _.debounce(() => {

			if(isLoading || !hasMore.current){
				return;
			}
			if((window.scrollY > FOOTER_START - 150)){
				currentPage.current = currentPage.current + 1;
				getNextPosts(false, currentPage.current, activeCategory);
			}
		}, 250)

		
	});

	const getNextPosts = () => {
		getNewPosts(false);
	};

	// useEffect(()=> {
	// // 	const categories = [];
	// }, []);

	// allPosts.map(post => {		

	// 	// 		if(post.node.full_slug === `blog/`){
	// 	// 			console.log(`found`)
	// 	// 		}
	
	// 	let content = 
	// 		typeof post.node.content === `string` 
	// 			? JSON.parse(post.node.content) 
	// 			: post.node.content
	// 		;
	// // 		const filter = content[filterBy];

	// // 		if(filter && !categories.find(({value}) => value === filter.toLowerCase().toLowerCase())){
	// // 			categories.push({ 
	// // 				value: filter.toLowerCase(),
	// // 				label: formatVertical(filter)
	// // 			});
	// // 		}
	// 	if(post.node.full_slug && post.node.full_slug.includes(`recap-2020`)){
	// 		content = {...prepareRecap(post.node)};
	// 	}
	// 	return post.node.content = content;

	// });

	const searcher = new FuzzySearch(
		allPosts, 
		[`node.content.title`, `node.content.category`, `node.content.content`]
	);


	const debouncedSearch = useRef(_.debounce(nextValue => {
		getNewPosts(true, 0)
	}, 1000)).current;


	const doSearch = (ev) => {	
		let term = ev.target.value;
		if(term.length > 2 || term.length === 0){

			if(term.length === 0) {
				currentPage.current = 1;
			}
		
			searchTerm.current = term;
			debouncedSearch(term);
		}

	};

	const changeCategory = category => {
		setActiveCategory(category);
		currentPage.current = 1;
		if(category === `all`){
			getNewPosts(true, currentPage.current, category);
			return;
		}
		getNewPosts(true, currentPage.current, category);

	};

	return (
		<SbEditable content={props.blok}>
			<div className="row w-100">
				{search && 
					<div className="col-12 col-md-5 col-lg-3 mb-3">
						<div id="search">
							<input type="text" placeholder="Search" onChange={(e)=> doSearch(e)} className="search-input w-100" id="search-input"/>
						</div>
					</div>
				}
				{filter && allCategories.length ?
					<div className="mr-0 ml-auto col-12 col-md-5 col-lg-3 d-flex justify-content-end align-items-center mb-3">
						<select 
							className="w-100 filter"
							name="Blog posts filter"
							onChange={(e)=>changeCategory(e.target.value)}
							defaultValue={`all`}
						>
							<option value="all">All</option>
							
							{allCategories.map((option, i) => {
								return i !== 0 && <option key={`option-${i}`} value={option.node.value}>{option.node.name}</option>;
							})}
						</select>
					</div>
					:
					``
				}
			</div>
			<div className="row w-100">

				{filteredPosts.map((post, i) => {	
					return post && post.node.full_slug !== `blog/` && post.node.full_slug.includes(`blog/`) && <PostCard key={post.node.uuid} post={post} />
				})}

			</div>
			<div className="row text-center w-100">
				<div className="col-12 mt-5 text-center">
					{ isLoading && <Loader width="80" height="80" /> }
				</div>
			</div>
		</SbEditable>
	);
};

export default Posts;

const prepareRecap = (story) => {

	let image = ``;

	if(story.full_slug === `blog/recap-2019`){
		image = `https://a.storyblok.com/f/72378/1855x1250/e2731434e1/thumb-recap2019-5x.png`;
	} else if(story.full_slug === `blog/recap-2020`){
		image = `https://a.storyblok.com/f/72378/742x500/6719c2f1d4/thumb-recap-2020-2x.png`
	}


	let recapObj = {
		...story.content,
		thumb: [
			{
				component: `image`,
				image: image
			}
		],
		title: story.name,
		date: story.field_date_string
	};

	return recapObj;

}


const PostCard = ({post}) => {

	let content = 
			typeof post.node.content === `string` 
				? JSON.parse(post.node.content) 
				: post.node.content
			;

	if(post.node.full_slug && post.node.full_slug.includes(`recap`)){
		content = {
			...prepareRecap(post.node)
		}
	}
	let parsedDate = content.date ? content.date.replace(/ /g, `T`) : ``;
	const date = parsedDate ? format(new Date(parsedDate), `MMMM do',' yyyy`) : ``;

	if(!content.thumb && !content.hero){
		console.log(`NO`)
		console.log(content);
		return;
	}

	const thumbImage = content.thumb ? content.thumb[0] : content.hero[0];
	if(thumbImage){	
		thumbImage.dimensions = {
			width: 330
		};
	}
	
	return (
		<div 
			className="col-sm-12 col-md-6 col-lg-4 d-flex flex-column post-container mt-3 post"
		>

			<div className="post-image-container">
				{thumbImage && 
					<Link to={getUrl(post.node.full_slug)} className="info">
						<Image blok={thumbImage} className="w-100" alt="" />
					</Link>
				}
			</div>
			<div className="post-text-container flex-column">
				<p className="xsmall mb-0">
					{date}
				</p>
				<h2>
					{content.title}
				</h2>
				<Link to={getUrl(post.node.full_slug)} className="info">
					<i className="fa fa-arrow-right text-blue"></i>
								Read more
				</Link>
			</div>
		</div>
	);
};

const getUrl = (path) => {
	if(path.charAt(0) !== `/`){
		return `/${path}`
	} 
	return path;
}

const formatVertical = title => {
	if(!title || !title.length) {
		return;
	}

	const titleArray = title.split(`-`);
	let finalTitle = ``;

	titleArray.forEach(word => {
		let formatted;
		if(word === `ks` || word === `salto`){
			formatted = word.toUpperCase();
		} else {
			formatted = word.charAt(0).toUpperCase() + word.substring(1);
		}

		finalTitle = finalTitle.concat(formatted, ` `);
	});

	return finalTitle.slice(0, -1); // Remove the extra space in the end
};

PostCard.propTypes = {
	post: PropTypes.shape({
		node: PropTypes.shape({
			first_published_at: PropTypes.string,
			full_slug: PropTypes.string,
			content: PropTypes.shape({
				thumb: PropTypes.array,
				title: PropTypes.string
			})
		})
	})
};

Posts.propTypes = {
	blok: PropTypes.shape({
		card_section: PropTypes.arrayOf(
			PropTypes.shape({
				component: PropTypes.string.isRequired,
				_uid: PropTypes.string.isRequired
			})
		),
		type: PropTypes.string,
		search: PropTypes.bool,
		filter: PropTypes.bool
	}).isRequired
};
