import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Image from '../elements/Image';

const Brick = (props) => {
	const {
		name,
		image,
		description,
		linkedin_link
	} = props.blok;

	return (
		<SbEditable content={props.blok}>
			<div className="brick-wrapper">
				<Image blok={image[0]} />
				<div className="d-flex align-center mt-3 mb-2 justify-content-between">
					<h4 className="m-0">{name}</h4>
					<a style={{display: `inherit`}} href={linkedin_link} target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"/></a>
				</div>
				<p className="small">{description}</p>
			</div>
		</SbEditable>
	);
};

export default Brick;

Brick.propTypes = {
	blok: PropTypes.shape({
		link: PropTypes.shape({
			cached_url: PropTypes.string
		}),
		title: PropTypes.array,
		classes: PropTypes.string,
		text: PropTypes.array
	})
};