import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Image from '../elements/Image';
import CustomLink from '../elements/CustomLink';

const PartnerCard = (props) => {

	return (
		<SbEditable content={props.blok}>
			<div className='card-icon h-100'>
				{ props.blok.banner_text && <div className="corner-ribbon top-right">{props.blok.banner_text}</div> }
				{ props.blok.link && props.blok.link.url ? 
					<a className="card-wrapper w-100 d-flex flex-column" href={props.blok.link.url} target="_blank" rel="noopener noreferrer">
						<CardContent card={props.blok}/>
					</a>
					: 
					<div className="card-wrapper w-100 d-flex flex-column">
						<CardContent card={props.blok}/>
					</div>
				}
				
			</div>
		</SbEditable>
	);
};

const CardContent = ({card}) => {

	const { website_link, blog_link } = card;

	return <>
		{ card.logo && <>
			<div 
				className="d-flex flex-column align-items-center justify-content-center pt-4 m-0 flex-grow-1 flex-grow"
			>
				{ card.logo && 
					<Image blok={card.logo[0]} />
				}
			</div>
			<span className="mt-4 small d-block text-grey cat-label bordered">
				{card.vertical}
			</span>
		</>
		}
		<div className="text-left mt-auto mb-0 py-3">
			{ card.features && card.features.map(feature => {
				const status = feature.feature_status;
				return(
					<p className="small text-dark-grey" key={feature._uid}>
						{renderIcon(status)}
						{feature.feature_name}
						<span> · </span>
						<span className={`${statusStyle(status)} `}>
							{formatStatus(status)}
						</span>
					</p>
				);
			})}
		</div>
		{ blog_link?.cached_url && <CustomLink blok={{link: {...blog_link}, icon: {icon: `fa-arrow-right`}, text: `Read more`, classes:`text-left`}} /> }
		{ website_link?.url && <CustomLink blok={{link: {...website_link}, icon: {icon: `fa-arrow-right`}, text: `Visit the website`, classes:`text-left`}} />}
	</>;
};

const formatStatus = status => {
	// Remove all dashes and capitalize first letter
	status = status.replace(/-/g, ` `);
	return status.charAt(0).toUpperCase() + status.substring(1);
};

const renderIcon = (status) => {
	const icon = status === `available` ? `fa-check` : `fa-minus`;
	return <i className={`fa pr-2 ${icon}`}></i>;
};

const statusStyle = (status) => {
	let style = ``;
	switch(status) {
	case `available`:
		style = `text-green strong`;
		break;
	case `coming-soon`:
		style = `text-green`;
		break;
	}
	return style;
};

export default PartnerCard;

PartnerCard.propTypes = {
	blok: PropTypes.shape({
		features: PropTypes.arrayOf(
			PropTypes.shape({
				_uid: PropTypes.string,
				feature_name: PropTypes.string,
				feature_status: PropTypes.string
			})
		),
		link: PropTypes.object,
		card: PropTypes.object,
		logo: PropTypes.array,
		vertical: PropTypes.string
	}).isRequired
};

CardContent.propTypes = {
	card: PropTypes.object
};