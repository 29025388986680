import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Components from '../Components';
import Image from '../elements/Image';

const HorizontalScroll = (props) => {

	const scrollContainer = useRef();
	const windowWidth = useRef();

	useEffect(() => {

		windowWidth.current = window.innerWidth;
		let extraSpace = 120;
		scrollContainer.current.addEventListener(`mousemove`, function(e){
			let mousePositionX = e.clientX;
			let delta = this.children[0].offsetWidth + extraSpace - windowWidth.current;
			let percent = 100 / windowWidth.current * mousePositionX;
			let offset = -1 * delta / 100 * percent

			this.children[0].style.transform = `translateX(${offset}px)`;
		})
	}, []);


	const {
		icon,
		items,
		classes
	} = props.blok;

	return (
		<SbEditable content={props.blok}>
			<div ref={scrollContainer} className={`${classes ? classes : ``} milestones-inner`}>
				<div>
					{icon && 
						<div className="scroll-icon d-flex justify-content-end">
							<Image blok={icon[0]} />
						</div>
					}

					{items && items.map(milestone=> {
						return (
							<div key={milestone._uid} className="milestone">
								{milestone.content[0].component === `image` && 
									<div className="image">
										<Image blok={milestone.content[0]} />
									</div>
								}

								{milestone.content.map((item, i) => {
									return item.component !== `image`
										&& React.createElement(
											Components(item.component), 
											{
												key: item._uid, 
												blok: item
											}
										)
									;
										
								})}
							</div>
						);
					
					})}
				</div>
			</div>
		</SbEditable>
	);
};

export default HorizontalScroll;

HorizontalScroll.propTypes = {
	blok: PropTypes.shape({
		classes: PropTypes.string,
		icon: PropTypes.shape,
		items: PropTypes.arrayOf(
			PropTypes.shape({
				item: PropTypes.shape({
					component: PropTypes.string.isRequired,
					_uid: PropTypes.string.isRequired,
				})
			})
		)
	}).isRequired
};