import React from 'react';
import PropTypes from 'prop-types';
import RichText from '../elements/RichText';
import SbEditable from 'storyblok-react';

const SecuritySlide = (props) => {

	const blok = { 
		content: props.blok.content 
	};
	return (
		<SbEditable content={props.blok}>
			<div className="component-slide">
				<h4>{props.blok.title}</h4>
				<div className="content">
					<RichText blok={blok} />
				</div>
				<div className="component-footer">
					{props.showNext && <NextButton text="Next" func={props.buttons.next} />}
					{props.showPrev && <PrevButton text="Previous" func={props.buttons.prev} />}
					
				</div>
			</div>
		</SbEditable>
	);
};

SecuritySlide.propTypes = {
	blok: PropTypes.shape({
		title: PropTypes.string,
		content: PropTypes.object
	}),
	buttons: PropTypes.shape({
		next: PropTypes.func,
		prev: PropTypes.func
	}),
	showNext: PropTypes.bool,
	showPrev: PropTypes.bool
};

const NextButton = ({text, func}) => (
	<button 
		onClick={func} 
		className="info change-component next unstyled-btn" 
	>
		{text}
		<i className="fa fa-arrow-right ml-1"></i>
	</button>
);

NextButton.propTypes = {
	text: PropTypes.string,
	func: PropTypes.func
};


const PrevButton = ({text, func}) => (
	<button 
		onClick={func} 
		className="info change-component prev unstyled-btn" 
	>	
		<i className="fa fa-arrow-left mr-1"></i>
		{text}
		
	</button>
);

PrevButton.propTypes = {
	text: PropTypes.string,
	func: PropTypes.func
};

export default SecuritySlide;
