import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import SbEditable from 'storyblok-react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { generateUrl, getFormat } from '../../utilities/image';
import { prepareUrl } from '../../utilities/helpers';
import Highlight, { defaultProps } from 'prism-react-renderer';
import codeTheme from "prism-react-renderer/themes/github";
import '@splidejs/splide/dist/css/themes/splide-default.min.css'

const URL = process.env.DEPLOY_PRIME_URL;

const QuoteTemplate = blok => {
	let imageUrl = generateUrl(`//a.storyblok.com/f/72378/36x40/048b6c104b/quote-brackets.png`);
	return `
		<div class="quote-wrapper my-5">
			<img class="quote-bracket" src="${imageUrl}" alt="Quotation mark">
			<blockquote>${blok.quote}</blockquote>
			${QuoteInfo(blok)}
		</div>
	`;
};

const QuoteInfo = blok => {
	if (blok.author.length) {
		return `
			<div class="quote-info mt-4">
				<span class="strong small">${blok.author}</span>
				${blok.position.length ? `<span class="small">${blok.position}</span>` : ``}
			</div>
		`;
	} else {
		return ``;
	}
};

const imageProcess = src => {
	if (src.includes(`http://`)) {
		src = src.replace(`http:`, ``);
	}
	if (getFormat(src) === `svg`) {
		return src;
	}
	return generateUrl(src);
};

const RichText = props => {

	useEffect(() => {
		let carousels = document.getElementsByClassName(`splide-carousel`);
		carousels.length && [...carousels].forEach(carousel => {
			new Splide(`#${carousel.id}`, {
				type: `loop`,
				rewind: true,
				pagination: true
			}).mount();
		})
	}, []);

	const isStoryChief =
        props.blok.content_storychief && props.blok.content_storychief.length;

	// const MySchema = require('../../richtext-schema')(props.amp);
	// Storyblok.richTextResolver = new RichTextResolver(MySchema);

	// ampify(content, {}).then(res => console.log(res));

	const storyChief = content => {
		return (
			<div
				className={`text-wrapper ${props.blok.classes}`}
				dangerouslySetInnerHTML={{ __html: content }}
			></div>
		);
	};

	const normalContent = blok => {

		if (blok.content === `string`) {
			return (
				<div className="markdown-wrapper">
					<ReactMarkdown
						source={blok.content}
						transformImageUri={uri => imageProcess(uri)}
						renderers={{ image: e => ReturnImage(e) }}
					/>
				</div>
			);
		} else {
			let parsedContent = testParser(blok.content);
			return (
				<div
					className={`text-wrapper richtext-wrapper ${blok.classes}`}
					dangerouslySetInnerHTML={{ __html: parsedContent }}
				/>
			);
		}
	};

	return (
		<SbEditable content={props.blok}>
			{isStoryChief
				? storyChief(props.blok.content_storychief)
				: normalContent(props.blok)}
		</SbEditable>
	);
};

RichText.propTypes = {
	blok: PropTypes.shape({
		content: PropTypes.object,
		classes: PropTypes.string,
	}),
};

export default RichText;

const testParser = object => {
	if (!object || !object.type) {
		return ``;
	}
	switch (object.type) {
		case `doc`:
			return `${renderArray(object.content)}`;
		case `paragraph`:
			return `${renderParagraph(object.content)}`;
		case `ordered_list`:
			return `${renderList(object.content, `ol`)}`;
		case `bullet_list`:
			return `${renderList(object.content, `ul`)}`;
		case `list_item`:
			return `${renderListItem(object.content)}`;
		case `heading`:
			return `${renderHeading(object)}`;
		case `blok`:
			return `${renderStoryblokComponent(object)}`;
		case `image`:
			return `${renderImage(object)}`;
		case `blockquote`:
			return `${renderBlockQuote(object)}`;
		case `horizontal_rule`:
			return `<hr/>`;
		case `code_block`:
			return `${renderCodeBlock(object)}`;
	}
};

const renderCodeBlock = object => {

	let language = object.attrs.class;
	if(language && language.startsWith(`language-`)){
		language = language.substring(9);
	};

	if(!object || !object.content || !object.content.length) {
		return null;
	}

	return ReactDOMServer.renderToStaticMarkup(
		<Highlight
			{...defaultProps}
			code={object.content[0].text}
			language={language ? language.toLowerCase() : `bash`}
			theme={codeTheme}
		>
			{({ className, style, tokens, getLineProps, getTokenProps }) => (
				<pre className={className} style={style}>
					{tokens.map((line, i) => (
						<div {...getLineProps({ line, key: i })}>
							{line.map((token, key) => (
								<span {...getTokenProps({ token, key })} />
							))}
						</div>
					))}
				</pre>
			)}
		</Highlight>
	);

};

const renderBlockQuote = object => {
	let returnString = `<blockquote class="block-quote-new">`;
	object.content.forEach(item => {
		returnString = `${returnString}${testParser(item)}`;
	});

	returnString = `${returnString}</blockQuote>`;

	return returnString;
};

const renderImage = object => {
	let returnString = `<img src="`;
	return returnString;
};

const renderHeading = object => {
	if (!object.content) {
		return ``;
	}

	let returnString = `<h${object.attrs.level}>`;

	object.content.forEach(item => {
		if (item.type === `text`) {
			returnString = `${returnString}${item.text}`;
		}
	});

	returnString = `${returnString}</h${object.attrs.level}>`;
	return returnString;
};
const renderArray = array => {
	let returnString = ``;

	array.forEach(item => {
		returnString = `${returnString}${testParser(item)}`;
	});
	return returnString;
};

const renderParagraph = paragraphs => {
	if (!paragraphs) {
		return ``;
	}
	let returnString = `<p>`;
	paragraphs.forEach(paragraph => {
		if (paragraph.type === `text`) {
			let classes = ``;
			let link = ``;
			let bold = false;
			let modifiers = [];
			let modifiersClose = [];
			paragraph.marks &&
                paragraph.marks.forEach(mark => {
                	if (mark.type === `styled` && mark.attrs.class.length) {
                		classes = mark.attrs.class;
                	}
                	if (mark.type === `link`) {
                		let link;
                		let external = false;

                		if (mark.attrs.linktype === `url`) {
                			external = true;
                			if (!mark.attrs.href.startsWith(`https`)) {
                				link = `https://${mark.attrs.href}`;
                			} else {
                				link = mark.attrs.href;
                			}
                		} else if (mark.attrs.linktype === `email`) {
                			link = `mailto:${mark.attrs.href}`;
                		} else {
                			link = prepareUrl(mark.attrs.href);
                		}

                		modifiers.push(
                			`<a href=${link} target="_blank" rel="noopener noreferrer">`
                		);
                		modifiersClose.push(`</a>`);
                	}
                	if (mark.type === `bold`) {
                		modifiers.push(`<strong>`);
                		modifiersClose.push(`</strong>`);
                	}
                	if (mark.type === `italic`) {
                		modifiers.push(`<em>`);
                		modifiersClose.push(`</em>`);
                	}
                	if (mark.type === `code`) {
                		modifiers.push(`<code>`);
                		modifiersClose.push(`</code>`);
                	}
                });

			let append = ``;
			let prepend = ``;

			modifiers.forEach((mod, i) => {
				prepend = `${prepend}${mod}`;
				append = `${append}${
					modifiersClose[modifiersClose.length - 1 - i]
				}`;
			});

			let paragraphText = ``;

			if (append.length && prepend.length) {
				paragraphText = `${prepend && prepend}${
					paragraph.text
				}${append && append}`;
			} else {
				paragraphText = paragraph.text;
			}

			if (link.length) {
				returnString = `${returnString}${link}${paragraphText}</a>`;
			} else {
				returnString = `${returnString}${paragraphText}`;
			}
		} else if (paragraph.type === `hard_break`) {
			returnString = `${returnString}<br/>`;
		} else if (paragraph.type === `image`) {
			let classes = ``;
			if(paragraph.marks && paragraph.marks.length){
				paragraph.marks.forEach(mark => {
					if(mark.type === `styled`){
						classes = `${classes} ${mark.attrs.class}`;
					}
				})
			};
			let imageUrl = generateUrl(paragraph.attrs.src, 960, true);
			returnString = `${returnString}<img class="img-fluid ${classes}" alt="${paragraph.attrs.alt}" title="${paragraph.attrs.title}" src="${imageUrl}" />`;
		}
	});
	return `${returnString}</p>`;
};

const renderList = (list, tag) => {
	let returnString = `<${tag}>`;
	list.forEach(item => {
		returnString = `${returnString}${testParser(item)}`;
	});
	returnString = `${returnString}</${tag}>`;
	return returnString;
};

const renderListItem = items => {
	let returnString = `<li>`;
	items.forEach(item => {
		returnString = `${returnString}${testParser(item)}`;
	});
	returnString = `${returnString}</li>`;
	return returnString;
};

const renderStoryblokComponent = object => {
	let returnString = ``;

	object.attrs.body.forEach(blok => {
		returnString = `${returnString}${renderComponent(blok)}`;
	});
	return returnString;
};

const linkList = list => {
	let string = `<ul class="d-block w-100 my-2 pl-2">`;
	list.forEach(item => {
		string += `
			<li class="my-3 no-style-li">
				<a class="text-black hover-blue info" href="${prepareUrl(item.link.cached_url)}" target="_blank" rel="noopener noreferrer">
				<i class="fa fa-arrow-right"></i> ${item.text}</a>
			</li>`;
	});
	string += `</ul>`;
	return string;
};

const renderComponent = blok => {
	switch (blok.component) {
	case `div`:
		let imageUrl = blok.items[0].image ? generateUrl(blok.items[0].image, 900, true) : null
		return `
						<div class="${
	blok.classes ? blok.classes : `align-items-center`
} d-flex image-div ">
							${
	imageUrl
		? `<img src="${imageUrl}" width="${blok.items[0].dimensions.width}" />`
		: ``
}
							<p class="ml-3">	
								${
	blok.items[0].paragraph
		? blok.items[0].paragraph
		: blok.items[1]
			? blok.items[1].paragraph
			: ``
}
							</p>
						</div>
					`;
		break;
	case `button`:
		return `
					<p class="${blok.classes} text-center">
						<a class="btn btn-primary" href="${prepareUrl(blok.link.cached_url)}" ${
	blok.new_tab ? `rel="noopener noreferrer" target="_blank"` : ``
} >
							${blok.text}
						</a>
					</p>
				`;
		break;
	case `quote`:
		return QuoteTemplate(blok);
		break;
	case `bubble`:
		return `
					<div class="dev-method">
						${blok.content}
					</div>
				`;
		break;
	case `image`:
		let image = generateUrl(blok.image, blok.dimensions.width || 900, false);
		return `<img src="${image}" class="${blok.classes}" alt="${blok.alt}" />`;
		break;
	case `insta-embed`:
		return `
					<iframe class="instagram-media instagram-media-rendered" id="instagram-embed-0" src="${blok.link}" allowtransparency="true" allowfullscreen="true" frameborder="0" height="747" data-instgrm-payload-id="instagram-media-payload-0" scrolling="no" style="background: white; max-width: 658px; width: calc(100% - 2px); border-radius: 3px; border: 1px solid rgb(219, 219, 219); box-shadow: none; display: block; margin: 0px 0px 12px; min-width: 326px; padding: 0px;"></iframe>
				`;
		break;
	case `link-list`:
		return linkList(blok.items);
		break;
	case `html`:
		return `<div class="w-100 ${blok.classes ? blok.classes : ``}">${blok.content}</div>`;
		break;
	case `carousel`:
		return renderCarousel(blok)
	}
};

const renderCarousel = blok => {
	let string = `
		<div id="${blok._uid}" class="w-100 splide splide-carousel">

		<div class="splide__arrows">
			<button type="button" aria-label="previous slide / item" class="splide__arrow splide__arrow--prev"></button>
			<button type="button" aria-label="next slide / item" class="splide__arrow splide__arrow--next"></button>
		</div>


			<div class="splide__track">
				<ul class="splide__list">
	`;

	blok.items.forEach((item, i) => {
		let imageUrl = generateUrl(item.image, 960, true);
		string = `
			${string}
				<li class="splide__slide">
					<img class="d-block w-100" src="${imageUrl}" />
				</li>
		`
	});

	string = `${string}</ul></div></div>`
	return string;
}
