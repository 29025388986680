import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import SecuritySlide from './SecuritySlide';

const SecuritySlider = (props) => {
	const slides = props.blok.slides;
	const [activeSlide, setActiveSlide] = useState(0);
	const [showSlides, setShowSlides] = useState(false);

	const svgGraphic = useRef(null);

	const bindClicks = () => {
		const circles = svgGraphic.current.getElementsByClassName(`circle-nav`);
		
		//HTMLCollection to array
		[...circles].forEach(circle => {
			circle.addEventListener(`click`, function(){
				const slide = circle.getAttribute(`data-slide`);
				if(slide-1 < slides.length){
					setActiveSlide(slide-1);
				}
			});
		});
	};

	return (
		<>
			<SVG className={`security-svg ${showSlides ? `slide-${activeSlide + 1}` : ``}`} src={props.blok.graphic} innerRef={svgGraphic} onLoad={bindClicks}/>

			{ !showSlides && <button className="btn btn-primary show-slider" href="#" onClick={()=>setShowSlides(true)}>{props.blok.button_text}</button>}

			{ showSlides && 
				
				<div className="salto-components text-left">
					{slides && slides.map((slide, i) => {
						return i === activeSlide 
							? <SecuritySlide 
								blok={slide} 
								showNext={activeSlide !== slides.length-1} 
								showPrev={activeSlide !== 0} 
								buttons={{
									next: () => setActiveSlide(activeSlide + 1),
									prev: () => setActiveSlide(activeSlide - 1)
								}}
							/> 
							: false
						;
					})}
				</div>
			}
		</>
	);
};

SecuritySlider.propTypes = {
	blok: PropTypes.shape({
		slides: PropTypes.array,
		graphic: PropTypes.string,
		button_text: PropTypes.string
	}).isRequired,
	nav: PropTypes.object
};

export default SecuritySlider;
