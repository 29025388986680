const PROFILES = [
    {
        title: `Security Company`,
    },
    {
        title: `Locksmith / Installer`,
    },
    {
        title: `System Integrator / Developer`,
    },
    {
        title: `Architect`,
    },
    {
        title: `Security Consultant`,
    },
    {
        title: `Owner`,
        has_dimensions: true,
    },
    {
        title: `Property Manager`,
        has_dimensions: true,
    },
    {
        title: `SALTO Business Partner`,
    },
    {
        title: `SALTO Technology Partner`,
    },
];

const DIMENSIONS = [
    {
        title: `Commercial`,
        children: [
            {
                title: `Industry and Manufacturing facilities`,
                code: `010100`,
            },
            {
                title: `Airports and Transportation`,
                code: `010200`,
            },
            {
                title: `Government and Public Sector`,
                code: `010300`,
            },
            {
                title: `Mining facilities and Remote camps`,
                code: `010400`,
            },
            {
                title: `Freight and Logistics`,
                code: `010500`,
            },
        ],
    },
    {
        title: `Education`,
        children: [
            {
                title: `Universities and Campus`,
                code: `020100`,
            },
            {
                title: `Schools`,
                code: `020200`,
            },
            {
                title: `Student Accomodations`,
                code: `080100`,
            },
        ],
    },
    {
        title: `Hospitality`,
        children: [
            {
                title: `Luxury Hotels`,
                code: `030100`,
            },
            {
                title: `All-inclusive Resorts`,
                code: `030400`,
            },
            {
                title: `Convention and Conference Hotels`,
                code: `030500`,
            },
            {
                title: `Automated Self-service Hotels`,
                code: `030700`,
            },
            {
                title: `Marine`,
                code: `030800`,
            },
        ],
    },
    {
        title: `Healthcare`,
        children: [
            {
                title: `Hospitals and Clinics`,
                code: `040100`,
            },
            {
                title: `Nursing Homes / Senior Living`,
                code: `040200`,
            },
            {
                title: `Laboratories`,
                code: `040300`,
            },
        ],
    },
    {
        title: `Retail`,
        children: [
            {
                title: `Fast Food and Restaurant`,
                code: `050100`,
            },
            {
                title: `Supermarket and Discount Stores`,
                code: `050200`,
            },
            {
                title: `Retail banking and Insurance`,
                code: `050300`,
            },
            {
                title: `Clothes and Fashion Stores`,
                code: `050400`,
            },
        ],
    },
    {
        title: `Residential`,
        children: [
            {
                title: `Multi-Family Housing`,
                code: `060100`,
            },
            {
                title: `Single-Family Housing`,
                code: `060200`,
            },
            {
                title: `Vacation rental`,
                code: `060300`,
            },
            {
                title: `Home Care / Assisted living`,
                code: `060400`,
            },
        ],
    },
    {
        title: `Coworking`,
        children: [
            {
                title: `Coworking Spaces`,
                code: `070100`,
            },
        ],
    },
    {
        title: `Shared Living Spaces`,
        children: [
            {
                title: `Student Accomodations`,
                code: `080100`,
            },
            {
                title: `Coliving`,
                code: `080200`,
            },
        ],
    },
    {
        title: `Corporate Smart Workplace`,
        children: [
            {
                title: `Smart Office`,
                code: `090100`,
            },
        ],
    },
    {
        title: `Utilities and Critical Infrastructure`,
        children: [
            {
                title: `Telecommunications`,
                code: `100100`,
            },
            {
                title: `Power and Energy`,
                code: `100200`,
            },
            {
                title: `Oil, Gas and Water Treatment`,
                code: `100300`,
            },
        ],
    },
    {
        title: `Fitness`,
        children: [
            {
                title: `Fitness Clubs`,
                code: `110100`,
            },
            {
                title: `Medical Fitness / Rehabilitation`,
                code: `110200`,
            },
            {
                title: `Sports Club`,
                code: `110300`,
            },
        ],
    },
    {
        title: `Attractions, Leisure and Sport Facilities`,
        children: [
            {
                title: `Amusement Parks and Waterparks`,
                code: `120100`,
            },
            {
                title: `Museums and Cultural Heritage`,
                code: `120200`,
            },
            {
                title: `Zoos and Aquariums`,
                code: `120300`,
            },
            {
                title: `Public Swimming Pools`,
                code: `120400`,
            },
            {
                title: `Spa, Therms, Wellness`,
                code: `120500`,
            },
            {
                title: `Ski Depots`,
                code: `120600`,
            },
            {
                title: `Sport Facilities and Arenas`,
                code: `120700`,
            },
        ],
    },
];

export { PROFILES, DIMENSIONS };
