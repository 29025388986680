import PropTypes from "prop-types";
import React from "react";
import MainNav from "../ui/MainNav";

const Header = ({ headerStyle, nav, siblings }) => (
	<header className={`site-header ${headerStyle}`}>
		{ nav && <MainNav nav={nav} light={headerStyle === `transparent`} siblings={siblings} /> }
	</header>
);

Header.propTypes = {
	siteTitle: PropTypes.string,
	headerStyle: PropTypes.string,
	nav: PropTypes.object
};

Header.defaultProps = {
	siteTitle: `Salto KS`,
	headerStyle: `default`
};

export default Header;
