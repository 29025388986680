import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Image from '../elements/Image';
import {useStaticQuery, graphql, Link} from 'gatsby';

const SupportSections = (props) => {

	const data = useStaticQuery(graphql`
		query dataQuery {
			allStoryblokEntry(filter: {field_component: {eq: "page_support"}, lang: {eq: "default"}}) {
				edges {
					node {
						content
						full_slug
						id
						field_component
					}
				}
			}
		}
	`);

	let sections = [];
	
	data.allStoryblokEntry.edges.map(section => {

		let index = 0;
		switch(section.node.full_slug) {
			case `support/account`:
				index = 0;
				break;
			case `support/hardware`:
				index = 1;
				break;
			case `support/web-app`:
				index = 2;
				break;z
			case `support/mobile-apps`:
				index = 3;
				break;
			case `support/general`:
				index = 4;
				break;
			case `support/integrations`:
				index = 5;
				break;
			case `support/whats-new`:
				index = 6;
				break;	
		}
		const contentData = JSON.parse(section.node.content);

		sections[index] = {
			title: contentData.title,
			description: contentData.description,
			icon: contentData.icon[0],
			key: section.node.id,
			slug: section.node.full_slug
		};
	});

	return (
		<SbEditable content={props.blok}>
			<div className="support-sections">
				{sections.map(section => {
					return (
						<Link to={getLink(section.slug)} className="section mb-3" key={section.key}>
							<div className="icon">
								<Image blok={section.icon}/>
							</div>
							<div>
								<p className=" mb-0"><i className="fa fa-arrow-right text-primary small mr-2"></i>{section.title}</p>
								<p className="text-black small">
									{section.description}
								</p>
							</div>
						</Link>
						
					);
				})}
			</div>
		</SbEditable>
	);
};

const getLink = link => {
	if(typeof link === `string`){
		if(link.charAt(0) !== `/`){
			return `/${link}`;
		}
		return `${link}`;
	}
	
	if(link.linktype === `story`){
		if(link.cached_url.charAt(0) !== `/`){
			return `/${link.cached_url}`;
		}
		return `${link.cached_url}`;
	} else {
		return link.cached_url;
	}
};

export default SupportSections;

SupportSections.propTypes = {
	blok: PropTypes.shape
};
