import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Components from '../Components';
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CarouselWrapper = (props) => {
	const {
		arrows,
		bullets,
		autoplay
	} = props.blok;

	return (
		<SbEditable content={props.blok}>
			<Carousel 
				showThumbs={false}
				showStatus={false}
				showArrows={arrows}
				autoPlay={autoplay}
				showIndicators={bullets}
				infiniteLoop={true}
				interval={5000}
				className="w-100"
			>
				{
					props.blok.items.length && props.blok.items.map(item => {
						return React.createElement(
							Components(item.component), 
							{
								key: item._uid, 
								blok: item
							}
						);
					})
				}
			</Carousel>
		</SbEditable>
	);
};

export default CarouselWrapper;

CarouselWrapper.propTypes = {
	blok: PropTypes.shape({
		arrows: PropTypes.bool,
		bullets: PropTypes.bool,
		autoplay: PropTypes.bool,
		items: PropTypes.arrayOf(
			PropTypes.shape({
				_uid: PropTypes.string.isRequired,
				component: PropTypes.string.isRequired
			})
		)
	})
};
