import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';

const IconLine = (props) => {

	return (
		<SbEditable content={props.blok}>
			<p className={`icon-line mb-0 text-${props.blok.align} ${props.blok.classes}`}>
				{props.blok.icon && (
					<i className={`${props.blok.icon.icon} ${props.blok.icon_color} mr-2 fa`}></i>
				)}
				{props.blok.text}
			</p>
		</SbEditable>
	);
};

export default IconLine;

IconLine.propTypes = {
	blok: PropTypes.shape({
		classes: PropTypes.string,
		bordered: PropTypes.bool,
		icon: PropTypes.shape({
			icon: PropTypes.string,
		}),
		icon_color: PropTypes.string,
		text: PropTypes.string,
		align: PropTypes.string
	}).isRequired
};
