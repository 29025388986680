import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import axios from 'axios';
import { useStaticQuery, graphql } from 'gatsby';
import Loader from '../elements/Loader';

const Jobs = props => {
    const { link_text } = props.blok;
    const [jobsList, setJobsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const siteData = useStaticQuery(graphql`
        query SiteDataQuery {
            site {
                siteMetadata {
                    HOMERUN_API_KEY
                }
            }
        }
    `);

    useEffect(() => {
        const getJobsData = async () => {
            setLoading(true);
            let endpointUrl = `https://saltoks.com/.netlify/functions/jobs`;
            let result;

            try {
                result = await axios.get(endpointUrl, {
                    params: {
                        status: `open`,
                    },
                });
            } catch (e) {
                console.error(e);
            }
            setLoading(false);
            if (result && result.data) {
                setJobsList([...result.data]);
            } else {
                setError(true);
            }
        };

        getJobsData();
    }, []);

    const SingleJob = ({ job }) => {
        return (
            <li>
                <a href={job.job_url} target="_blank" rel="noopener noreferrer">
                    <div>
                        <span className="list-title">{job.title}</span>
                        <p>{job.type}</p>
                    </div>
                    <div className="info">
                        <i className="fa fa-arrow-right text-blue mr-2"></i>
                        <span className="d-none d-md-inline text-black">
                            {link_text}
                        </span>
                    </div>
                </a>
            </li>
        );
    };

    SingleJob.propTypes = {
        job: PropTypes.shape({
            job_url: PropTypes.string,
            title: PropTypes.string,
            status: PropTypes.string,
            type: PropTypes.string,
        }).isRequired,
    };

    return (
        <SbEditable content={props.blok}>
            <div className="styled-list">
                <ul>
                    {loading ? (
                        <span className="d-block px-3 py-4">
                            <Loader width={24} height={24} /> Loading jobs
                        </span>
                    ) : (
                        <>
                            {jobsList.length ? (
                                jobsList.map(job => {
                                    return job.status === `open` ? (
                                        <SingleJob key={job.key} job={job} />
                                    ) : (
                                        ``
                                    );
                                })
                            ) : (
                                <span className="d-block px-3 py-4">
                                    {error
                                        ? `Something went wrong displaying all the open jobs. Please reload the page.`
                                        : `There are currently no open vacancies...`}
                                </span>
                            )}
                        </>
                    )}
                </ul>
            </div>
        </SbEditable>
    );
};

export default Jobs;

Jobs.propTypes = {
    blok: PropTypes.shape({
        link_text: PropTypes.string,
    }).isRequired,
};
