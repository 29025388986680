import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import EmailInput from './EmailInput';

const TextInput = (props) => {

	const {blok, register, errors} = props;

	const {
		label,
		name,
		required,
		type
	} = blok;

	const inputOptions = {
		type: type ? type : `text`,
		id: name,
		name: name,
		ref: register({ required })
	};

	return (
		<SbEditable content={blok}>
			{ type === `url`
				? <EmailInput blok={blok} register={register} errors={errors} />
				: <>
					<label>
						{label} {required ? `*` : `(Optional)`}
						<RenderInput type={type} options={inputOptions} />
					</label>
					<span 
						role="alert" 
						className={
							`validation-error small ${errors[name] ? `show` : `hide`}`
						}>
							This field is required.
					</span>
				</>
			}
		</SbEditable>
	);
};

const RenderInput = ({type, options}) => {
	return type === `textarea` 
		? <textarea {...options} cols="40" rows="10"/> 
		: <input type={type ? type : `text`} {...options} />
	;
};

export default TextInput;

RenderInput.propTypes = {
	type: PropTypes.string,
	options: PropTypes.object
};

TextInput.propTypes = {
	blok: PropTypes.shape({
		label: PropTypes.string,
		name: PropTypes.string,
		required: PropTypes.bool,
		type: PropTypes.string
	}),
	register: PropTypes.func,
	errors: PropTypes.object
};