import React, { useState } from 'react';
import FuzzySearch from 'fuzzy-search';

const DealersSearch = (props) => {
	const {dealersList, goToDealer} = props;

	const [filteredDealers, setFilteredDealers] = useState([]);
	const [searchVal, setSearchVal] = useState(``);

	const searcher = new FuzzySearch(
		dealersList, 
		[`title`, `city`, `country`],
		{sort: true}
	);

	const doSearch = (e) => {
		const term = e.target.value;
		
		setSearchVal(term);

		if(term.length === 0){
			setFilteredDealers([]);
			return;
		}
		if(term.length < 3){
			return;
		}
		
		const searchResult = searcher.search(term);
		setFilteredDealers([...searchResult.filter((result, i) => i < 5)]);
	};

	const showDealerOnMap = (position) => {
		goToDealer(position);
		setFilteredDealers([]);
		setSearchVal(``);
	};

	return (
		<div className="dealers-search">
			<div className="search d-flex">
				<input value={searchVal} onChange={(e)=> doSearch(e)} type="text" placeholder="Search dealers" className="dealers-search-input"/>
			</div>
			{filteredDealers.length ?
				<div className="dealers-results">
					{filteredDealers.map((dealer, i) => {
						return(
							<div className="dealer-result" key={`dealer${i}`} onClick={() => showDealerOnMap(dealer.position)}>
								<span className="strong d-block">{dealer.title}</span>
								<span>{dealer.city}, {dealer.country}</span>
							</div>
						);
					})}
				</div>
				: null
			}
		</div>
	);
};

export default DealersSearch;