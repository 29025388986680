import React from 'react';
import PropTypes from 'prop-types';
import RichText from '../elements/RichText';
import Layout from './Layout';
import DevMenu from './DevMenu';
import EndpointLayout from '../ui/EndpointLayout';

const PageEndpoint = props => {
	const [sdkSwitch, setSdkSwitch] = React.useState(0);

	if (!props.blok || !props.blok.content) {
		return null;
	}
	const { content } = props.blok.content;
	let desc = ``;
	for (let i = 0; i < content.length; i++) {
		let item = content[i];
		if (item.type === `paragraph`) {
			if (!item.content) {
				continue;
			}
			desc = `${item.content[0].text}...`;
			break;
		}
	}

	const meta = {
		headline: props.metadata.title,
		lang: `en`,
		title: `${props.metadata.title} - SALTO KS`,
		description:
            desc ||
            `SALTO KS, SALTO Keys as a Service - Wireless Access Control For Your Business. Cloud-Based, Real-Time And On-The-Go Smart Lock Solution. Go Keyless Now!`,
		keywords:
            props.blok.keywords ||
            `Access control, locks, wireless, cloud-based, keyless, entry, real-time`,
		full_link: props.slug,
		other: { ...props.metadata },
	};

	return (
		<Layout
			slug={props.slug}
			siblings={props.siblings}
			headerStyle={props.blok.header_style}
			nav={props.nav}
			layout="full-width"
			footer="flat"
			meta={meta}
		>
			<div className="row developers dev-content">
				<div className="col-md-3 col-dev-lg-2">
					{props.blok.navigation && (
						<DevMenu
							nav={props.blok.navigation.node}
							current={props.slug}
						/>
					)}
				</div>

				<div className="col-xs-12 col-dev-lg-10 col-md-9 box-light-gray pr-0 pl-0">
					<div className="row h-100 flex-lg-row">
						<div
							className={`${
								props.blok.endpointData
									? `col-lg-4`
									: `col-lg-8`
							} content`}
						>
							{props.blok.content && (
								<RichText blok={props.blok} />
							)}
							{props.blok.html_content && (
								<div
									dangerouslySetInnerHTML={{
										__html: props.blok.html_content,
									}}
								/>
							)}
							{/* README from repositories for the SDKs */}
							{props.slug === `developers/mobile-sdk` ? (
								<>
									<div className="dev-msdk-header-switch mt-5">
										<span className="mr-10">
                                            Show me content for:
										</span>
										<a
											className={`${
												sdkSwitch ? `` : `active`
											} mx-3`}
											onClick={() => setSdkSwitch(0)}
										>
											<i
												className="fa fa-apple mr-1"
												aria-hidden="true"
											></i>
                                            IOS
										</a>
										<a
											className={`${
												sdkSwitch ? `active` : ``
											} mx-3`}
											onClick={() => setSdkSwitch(1)}
										>
											<i
												className="fa fa-android mr-1"
												aria-hidden="true"
											></i>
                                            ANDROID
										</a>
									</div>
									<div
										className="mt-3"
										dangerouslySetInnerHTML={{
											__html: sdkSwitch
												? props.blok.documentationAndroid
												: props.blok.documentationiOs,
										}}
									/>
								</>
							) : null}
						</div>
						{props.blok.endpointData &&
                            Object.keys(props.blok.endpointData).length > 0 && (
							<div className="col-lg-8 code pr-0 pl-0">
								<EndpointLayout blok={props.blok} />
							</div>
						)}
					</div>
				</div>
			</div>
		</Layout>
	);
};

PageEndpoint.propTypes = {
	slug: PropTypes.string,
	siblings: PropTypes.object,
	blok: PropTypes.shape({
		body: PropTypes.array,
		header_style: PropTypes.string,
		layout: PropTypes.string,
		_uid: PropTypes.string.isRequired,
		endpointData: PropTypes.object,
		navigation: PropTypes.object,
		hero: PropTypes.shape({
			show: PropTypes.bool,
			heroClass: PropTypes.string,
		}),
		content: PropTypes.object,
	}).isRequired,
	nav: PropTypes.object,
};

export default PageEndpoint;
