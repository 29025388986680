import React from 'react';
import PropTypes from 'prop-types';
import Layout from './Layout';
import Answer from '../ui/Answer';
import { Link , useStaticQuery, graphql} from 'gatsby';

const PageSupport = (props) => {
	const {
		categories,
		title
	} = props.blok;

	const questionsData = useStaticQuery(graphql`
		query questionsQuery {
			allStoryblokEntry(filter: {field_component: {eq: "page_answer"}, lang: {eq: "default"}}, sort: {fields: first_published_at, order: DESC}) {
				edges {
					node {
						content
						field_title_string
						first_published_at
						full_slug
						id
					}
				}
			}
			allStoryblokDatasource(filter: {data_source: {eq: "support-categories"}}) {
				edges {
					node {
						data_source
						name
						value
					}
				}
			}
		}
	`);

	const meta = {
		headline: title,
		lang: `en`,
		title: `${title} - SALTO KS Support`, 
		keywords: props.blok.keywords || `Access control, locks, wireless, cloud-based, keyless, entry, real-time`,
		description: props.blok.excerpt || `SALTO KS, SALTO Keys as a Service - Wireless Access Control For Your Business. Cloud-Based, Real-Time And On-The-Go Smart Lock Solution. Go Keyless Now!`,
		full_link: `https://saltoks.com/${props.slug}`,
		other: {...props.metadata}
	};


	const sections = {};
	const allCategories = questionsData.allStoryblokDatasource.edges;

	const getCategoryTitle = cat => allCategories.find(category => category.node.value === cat).node.name;

	if(categories){
		questionsData.allStoryblokEntry.edges.filter(question => {
			const content = JSON.parse(question.node.content);
			return categories.includes(content.category);
		}).forEach(question => {
			const content = JSON.parse(question.node.content);
			if(!Object.prototype.hasOwnProperty.call(sections, content.category)){
				sections[content.category] = {
					title: getCategoryTitle(content.category),
					items: []
				};
			}
			sections[content.category].items.push(question.node);
		});
	}

	return (
		<Layout
			slug={props.slug}
			siblings={props.siblings}
			headerStyle="default"
			nav={props.nav}
			meta={meta}
		>
			<div className="row mt-5">
				<div className="col-12 d-flex align-items-center col-lg-3">
					<Link to="/support" className="info">
						<i className="fa fa-arrow-left"></i>
						Back
					</Link>
				</div>
			
				<div className="col-12 col-lg-9">
					<h2 className="m-0">{title}</h2>
				</div>
			</div>

			{Object.keys(sections).map((sectionKey, i) => {
				const section = sections[sectionKey];
				if(sectionKey !== `downloads`){
					return <Section section={section} />
				}
			})}

			{sections[`downloads`] && <Section section={sections[`downloads`]} />}
			
		</Layout>
	);
};

const Section = ({section}) => {
	return (
		<div key={`section-${section.title}`} className="row support-section">
			<div className="col-12 col-lg-3">
				<h3 className="category-title no-decoration mb-4 mb-lg-0">
					{section.title}
				</h3>
			</div>
			<div className="col-12 col-lg-9">
				{section.items.map(item => {
					const contentData = JSON.parse(item.content);
					const content = contentData.category === `downloads` ? contentData : contentData.content;
					return (
						<Answer 
							key={item.id}
							blok={{
								title: contentData.title,
								content: content,
								slug: item.full_slug
							}}
						/>
					);
				})}
			</div>
		</div>
	);
}

PageSupport.propTypes = {
	siblings: PropTypes.object,
	slug: PropTypes.string,
	blok: PropTypes.shape({
		_uid: PropTypes.string.isRequired,
		categories: PropTypes.array,
		title: PropTypes.string,
		description: PropTypes.string,
		content: PropTypes.object
	}).isRequired,
	nav: PropTypes.object
};

export default PageSupport;

const formatTitle = title => {
	const titleArray = title.split(`-`);
	let finalTitle = ``;

	titleArray.forEach(word => {
		const formatted = word.charAt(0).toUpperCase() + word.substring(1);
		finalTitle = finalTitle.concat(formatted, ` `);
	});

	return finalTitle;
};
