import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Components from '../Components';
import Popup from 'reactjs-popup';

const Modal = (props) => {
	const {
		modal_trigger,
		modal_content,
		classes
	} = props.blok;

	const Trigger = () => (
		<div className={`pointer position-relative ${classes}`}>
			{
				modal_trigger && modal_trigger.map(item => {
					return React.createElement(
						Components(item.component), 
						{
							key: item._uid, 
							blok: item
						}
					);
				})
			}
		</div>
	);
	
	return (
		<SbEditable content={props.blok}>
			<Popup
				trigger={Trigger()}
				modal
				lockScroll
				position="center center" 
				className={classes}
			>{ close => (
					<>
						<button onClick={()=>close()} className="close p-4 pr-4"><span>×</span></button>
						<div className="p-4">
							{
								modal_content && modal_content.map(item => {
									return React.createElement(
										Components(item.component), 
										{
											key: item._uid, 
											blok: item
										}
									);
								})
							}
						</div>
					</>
				)}
			</Popup>
		</SbEditable>
	);
};

export default Modal;

Modal.propTypes = {
	blok: PropTypes.shape({
		modal_trigger: PropTypes.array,
		modal_content: PropTypes.array,
		classes: PropTypes.string
	})
};