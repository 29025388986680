import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Components from '../Components';

const Box = (props) => {
	const {
		title,
		link,
		text,
		classes
	} = props.blok;

	return (
		<SbEditable content={props.blok}>
			<a href={`${link.cached_url}`} className={classes}>
				{title && title.map(item => {
					return React.createElement(
						Components(item.component), 
						{
							key: item._uid, 
							blok: item
						}
					);
				})}

				{text && text.map(item => {
					return React.createElement(
						Components(item.component), 
						{
							key: item._uid, 
							blok: item
						}
					);
				})}

			</a>
		</SbEditable>
	);
};

export default Box;

Box.propTypes = {
	blok: PropTypes.shape({
		link: PropTypes.shape({
			cached_url: PropTypes.string
		}),
		title: PropTypes.array,
		classes: PropTypes.string,
		text: PropTypes.array
	})
};