import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import SbEditable from 'storyblok-react';
import VisibilitySensor from 'react-visibility-sensor';
import {ImageHelper} from '../../utilities/helpers';
import { IMAGE_SIZES, UNSUPPORTED_IMAGE_FORMATS } from '../../utilities/constants';

const Image = (props) => {	

	// Set a default alt attribute if none given by the user
	const alt = props.blok.alt 
		? props.blok.alt 
		: `SALTO Keys as a Service - Wireless Access Control For Your Business`
	;

	const {
		dimensions,
		classes,
		image,
		overlay,
		min_height
	} = props.blok;
	
	
	const [render, setRender] = useState(false);
	const [imageLoaded, setImageLoaded] = useState(false);

	if (!image){
		return null;
	}

	let 
		customDimensions,
		supportedFormat = true,
		src
	;

	if(UNSUPPORTED_IMAGE_FORMATS.includes(ImageHelper.getFormat(image))) {
		supportedFormat = false;
	}

	const originalDimensions = ImageHelper.getOriginalDimensions(image);
	let newDimensions = {};
	if(dimensions && dimensions.width){
		newDimensions.width = dimensions.width;
		newDimensions.height = dimensions.width / originalDimensions.ratio;
	} else {
		newDimensions.width = originalDimensions.width;
		newDimensions.height = originalDimensions.width / originalDimensions.ratio;
	}

	customDimensions = {...newDimensions};


	const workingSizes = IMAGE_SIZES.filter(size => size <= newDimensions.width * 2);

	let tempSrc = ``;	
	let tempWebpSrc = ``;
	workingSizes.forEach(size => {
		if(size <= originalDimensions.width){
			tempSrc = `${ImageHelper.generateUrl(image, size, false)} ${size}w, ${tempSrc}`;
			tempWebpSrc = `${ImageHelper.generateUrl(image, size, true)} ${size}w, ${tempWebpSrc}`;
		}
	});

	src = {
		default: tempSrc,
		webp: tempWebpSrc
	};

	const setVisibility = isVis => {
		if(render) {
			return;
		}
		setRender(isVis);
	};


	const getDimensions = () => {
		return dimensions && dimensions.width
			? { width: `${customDimensions.width}px`, maxHeight: `${customDimensions.height}px` } 
			: { width: `100%`, maxHeight: `${customDimensions.height}px`, justifyContent: `center`};
	};

	const getWidth = () => {
		return dimensions && dimensions.width 
			? { width: `${dimensions.width}px`}
			: {};
	};

	const RenderImage = () => {
		if(supportedFormat){
			return ( 
				<SbEditable content={props.blok}>
					<div 
						className={
							`picture-wrapper ${overlay !== `none` && `gradient-overlay-${overlay}`} ${classes}`
						} 
						style={getDimensions()}
					>
						<picture className={`${dimensions && dimensions.fluid && `w-100`}`}>
							<source 
								srcSet={src.webp}
								type="image/webp"
							/>
							<source 
								srcSet={src.default}
							/>
							<img alt={alt} src={ImageHelper.generateUrl(image)} onLoad={setImageLoaded.bind(null, true)}/>
						</picture>
						{!imageLoaded && <div style={getPlaceholderDimensions()} className={`${classes} img-placeholder`} />}
					</div> 
				</SbEditable>
			); 
		} else {
			return (
				<SbEditable content={props.blok}>
					<img 
						className={classes} 
						alt={alt} 
						src={ImageHelper.generateUrl(image)} 
						style={getWidth()}
					/>
				</SbEditable>
			);
		}
	};


	const getPlaceholderDimensions = () => {

		if(dimensions && dimensions.width){
			if(!customDimensions.height){
				return {
					width: `${customDimensions.width}px`,
					height: `150px`
				}
			}
			return {
				width: `${customDimensions.width}px`,
				height: `${customDimensions.height}px`
			}
		} else {
			return {
				width: `100%`,
				height: `200px`
			}
		}
	}
	return(
		<VisibilitySensor 
			onChange={setVisibility} 
			partialVisibility={true}
			offset={{bottom: -250}}
		>
			{ render 
				? <RenderImage /> 
				: <div className={classes} style={{position: `relative`, ...getPlaceholderDimensions()}}><div style={getPlaceholderDimensions()} className={`${classes} img-placeholder`} /></div>
			}
		</VisibilitySensor>

	);
};

export default Image;

Image.propTypes = {
	blok: PropTypes.shape({
		image: PropTypes.string,
		classes: PropTypes.string,
		alt: PropTypes.string,
		min_height: PropTypes.string,
		dimensions: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				fluid: PropTypes.boolean,
				width: PropTypes.string
			})
		]),
		overlay: PropTypes.string
	})
};
