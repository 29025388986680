import React, {useState} from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Components from '../Components';
import Image from '../elements/Image';

const Tabs = props => {
	const {
		items,
		title
	} = props.blok;

	const [activeTab, setActiveTab] = useState(items[0]._uid);

	const NavigationItem = ({item}) => {
		return (
			<li key={item._uid} className="nav-item">
				<button
					className={`clear ${activeTab === item._uid && `active`}`}
					onClick={()=>setActiveTab(item._uid)}
				>
					{item.title}
				</button>
			</li>
		);
	};

	NavigationItem.propTypes = {
		item: PropTypes.shape({
			title: PropTypes.string
		})
	};
	
	const TabContent = ({item}) => {
		return (
			<div className="tab-pane active">
				{item.content.map(component => {
					return React.createElement(
						Components(component.component), 
						{
							key: component._uid, 
							blok: component
						}
					);
				})}
			</div>
		);
	};

	TabContent.propTypes = {
		item: PropTypes.shape({
			content: PropTypes.array
		})
	};

	const Images = ({item}) => {
		return (
			<div>
				{item.images.map(image => <Image key={image._uid} blok={image} />)}
			</div>
		);
	};

	Images.propTypes = {
		item: PropTypes.shape({
			images: PropTypes.array
		})
	};

	return (
		<SbEditable content={props.blok}>
			<>
				{/* LEFT SIDE */}
				<div className="ways-to-unlock col-12 col-md-6 col-lg-4 offset-lg-1">
					{ title && <h3>{title}</h3> }
					<ul className="nav nav-tabs my-4">
						{
							items.map((item) => <NavigationItem key={item._uid} item={item} />)
						}
					</ul>

					<div className="tab-content">
						{items.map((item) => (item._uid === activeTab && <TabContent key={item._uid} item={item} />))}
					</div>
				</div>

				{/* RIGHT SIDE */}
				<div className="ways-to-unlock col-12 col-md-6 offset-lg-1 d-flex align-items-start justify-content-center ways-to-unlock-slider">
					{items.map((item) => (item._uid === activeTab && <Images key={item._uid} item={item} />))}
				</div>
			</>
		</SbEditable>
	);
};

export default Tabs;

Tabs.propTypes = {
	blok: PropTypes.shape({
		items: PropTypes.arrayOf(
			PropTypes.shape({
				_uid: PropTypes.string
			})
		),
		title: PropTypes.string
	})
};