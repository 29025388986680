import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import SVG from 'react-inlinesvg';
import Popup from 'reactjs-popup';
import RichText from '../elements/RichText';

const ColivingGraphic = (props) => {

	const {
		graphic,
		modals
	} = props.blok;

	const svgGraphic = useRef(null);
	const [activeModal, setActiveModal] = useState(null);

	const bindClicks = () => {
		const circles = svgGraphic.current.querySelectorAll(`a[data-toggle="custom-modal"]`);
		
		//HTMLCollection to array
		[...circles].forEach(circle => {
			circle.addEventListener(`click`, function(){

				 // The data-target starts with # (which we dont need
				const modal = circle.getAttribute(`data-target`).substring(1);
				setActiveModal(modal);
			});
		});
	};

	return (
		<SbEditable content={props.blok}>
			<>
				<div className="svg-expand">
					<SVG src={graphic} innerRef={svgGraphic} onLoad={bindClicks} />
				</div>
				{modals.map(modal => (
					<Popup 
						key={modal._uid}
						open={activeModal === modal.id}
						onClose={()=>setActiveModal(null)}
						closeOnDocumentClick
						modal
						lockScroll
						position="center center" 
					>{close => (
							<>
								<button onClick={close} className="close p-4 pr-4"><span>×</span></button>
								<div className	="p-4">
									<RichText blok={modal.content[0]} />
								</div>
							</>
						)}
					</Popup>
				)
				)}
			</>
		</SbEditable>
	);
};

export default ColivingGraphic;
