import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useState, useEffect } from 'react';
import FuzzySearch from 'fuzzy-search';
import DataTable from 'react-data-table-component';
import exportFromJSON from 'export-from-json';
import Helmet from 'react-helmet';
import axios from 'axios';
import LoaderImg from '../../images/loader_transparent.gif'


const LeadsDatabase = (props) => {

	const StoryblokClient = require(`storyblok-js-client`);

	const [renderTable, setRenderTable] = useState(false);
	const [loginError, setLoginError] = useState(``);
	const [password, setPassowrd] = useState(``);

	const [leads, setLeads] = useState({});
	const [source, setSource] = useState(``);
	const [page, setPage] = useState(`1`);
	const [sortBy, setSortBy] = useState(`date`);
	const [sortDir, setSortDir] = useState(`desc`);
	const [perPage, setPerPage] = useState(20);
	const [totalRows, setTotalRows] = useState(0);

	const [loading, setLoading] = useState(false);

	const Storyblok = new StoryblokClient({
		accessToken: `rTEXKYwPJo5RgIS25Vl9qwtt`,
		cache: {
			clear: `auto`,
			type: `memory`
		}
	});

	const getLeads = async (source, page, perPage, sortBy, sortDir) => {
		setLoading(true);
		let options = {
			"starts_with": `leads-database/`,
			"per_page": perPage,
			"page": page,
		};

		if(sortBy) {
			options[`sort_by`] = `content.${sortBy}:${sortDir}`
		}

		if(source){
			options[`filter_query`] = {
				"source": {
					"in": source
				}
			}
		};

		const leadsData = await Storyblok.get(`cdn/stories`,options);

		if(leadsData.total){
			setTotalRows(leadsData.total);
		}


		if(leadsData.data.stories){
			const stories = leadsData.data.stories;
			// Remove the index page with id 9647700
			const parsedItems = stories.filter(item => item.id !== 9647700).map(item => {
				if(item.content.info){
					return {
						...item.content,
						info: JSON.parse(item.content.info)
					};
				} else {
					return {
						...item.content,
						info: {}
					};
				}
			});
			setLeads(parsedItems);
		}
		setLoading(false);

		// 	setLeads({
		// 		all: parsedItems,
		// 		quote: parsedItems.filter(item => item.source === `quote`),
		// 		ksconnect: parsedItems.filter(item => item.source === `ksconnect`),
		// 		whitepaper: parsedItems.filter(item => item.source === `whitepaper`),
		// 		coworkingInfographic: parsedItems.filter(item => item.source === `coworking-infographic`),
		// 		biocoteWhitepaper: parsedItems.filter(item => item.source === `biocote-whitepaper`)
		// 	});
		// }
	};

	const handlePageChange = newPage => {
		setPage(newPage);
		getLeads(source, newPage, perPage, sortBy, sortDir);
	}

	const auth = async (password) => {
		
		const result = await axios({
			method: `POST`,
			url: `${window.location.origin}/.netlify/functions/database-auth`,
			data: {
				password: password
			},
			headers: {
				'content-type': `application/json`
			}
		});
		return result;
	};

	const handleSubmit = async(e) => {
		e.preventDefault();
		setLoginError(``);
		setLoading(true);
		if(typeof document !== `undefined`){
			const result = await auth(password);
			if(result.status === 200 && result.data.access){
				getLeads(source, page, perPage, sortBy, sortDir);
				setRenderTable(true);
			} else {
				setLoginError(`Something went wrong, please try again!`);
			}
			setLoading(false);
		}
	};


	const handleSort = (col, direction) => {

		setSortBy(col.selector);
		setSortDir(direction);
		getLeads(source, page, perPage, col.selector, direction);
	}

	const handlePerRowsChange = (newPerPage, newPage) => {
		setPerPage(newPerPage);
		setPage(newPage);
		getLeads(source, newPage, newPerPage, sortBy, sortDir);
	}

	const columns = [
		{
			name: `Title`,
			selector: `lead_name`,
			sortable: true,
		},
		{
			name: `Email`,
			selector: `email`,
			sortable: true,
		},
		{
			name: `Phone`,
			selector: `phone`,
			sortable: false,
		},
		{
			name: `Date`,
			selector: `date`,
			sortable: true,
		},
		{
			name: `Source`,
			selector: `source`,
			sortable: true
		},
		{
			name: `Status`,
			selector: `status`,
			sortable: true,
		},
	];

	const rowStyles = [
		{
			when: row => row.status && row.status.toLowerCase().includes(`fail`),
			style: {
				color: `red`
			}
		}
	];

	
	const RenderData= (props) => {

		const info = props.data.info;
		return <ul className="leads-table">
			{Object.keys(info).map((key, i) => {
				return (
					<li key={info.email + i}>
						{
							key === `account` 
								? (
									<>
										<span className="strong">Account Name & ID: </span>
										<pre>{info[key].name}<br/>
											{info[key].id}
										</pre>
									</>
								) : (
									<>
										<span className="strong">{key}: </span>
										<pre>{info[key] || ` `}</pre>
									</>
								)
						}
					</li>
				);
			})}
		</ul>;
	};

	const changeFilter = (e) => {
		setSource(e.target.value);
		getLeads(e.target.value, page, sortBy, sortDir)
	};

	const inputStyle= {
		width: `250px`
	};

	const doExport = e => {
		const type = e.target.value;

		if(type === `json` || type === `csv` || type === `xls`){
			const parsedData = leads.map(item => {
				return {
					source: item.source,
					date: item.date,
					name: item.lead_name,
					email: item.email,
					phone: item.phone || ``,
					country: item.info.country,
					state: item.info.state || ``,
					company_website: item.info.company_website,
					reason_of_interest: item.info.reason_of_interest,
					locks: item.info.nr_locks,
					locations: item.info.nr_locations,
					guests: item.info.nr_guests,
					extra_info: item.info.extra_info,
					utm_source: item.info.utm_source || ``
				};
			});

			exportFromJSON({ 
				data: parsedData, 
				fileName: `export`,
				exportType: type 
			});

		}
	};

	const loaderStyle = {
		position: `absolute`,
		top: 0,
		left: 0,
		zIndex: 9999,
		background: `rgba(255,255,255,.75)`,
		display: `flex`,
		justifyContent: `center`,
		alignItems: `center`,
		width: `100%`,
		height: `100%`
	}

	return (
		<div className="wrapper">
			<Helmet>
				<meta name="robots" content="none" />
			</Helmet>
			{renderTable ? 
				<>
					<div className="controls d-flex justify-content-end pt-4 pr-4">
						{/* <input style={inputStyle} className="mr-4" type="text" placeholder="Start typing to search leads..." onChange={(e)=>doSearch(e)}></input> */}
						<select className="mr-4" onChange={(e) => changeFilter(e)}>
							<option value="">
								Show all
							</option>
							<option value="quote">
								Get a quote
							</option>
							<option value="ksconnect">
								KS Connect
							</option>
							<option value="whitepaper">
								Whitepaper
							</option>
							<option value="coworking-infographic">
								Coworking infographic
							</option>
							<option value="biocote-whitepaper">
								Biocote Whitepaper
							</option>
						</select>
						<select onChange={(e) => doExport(e)}>
							<option value="">
								Export entries
							</option>
							<option value="json">
								JSON
							</option>
							<option value="csv">
								CSV
							</option>
							<option value="xls">
								XLS
							</option>
						</select>
					</div>

					<div style={{position: `relative`}}>
						{loading &&
							<div style={loaderStyle}>
								<img width="124" height="124" alt="Loading..." src={LoaderImg} />
							</div>
						}
						<DataTable
							columns={columns}
							data={leads}
							expandableRows={true}
							expandableRowsComponent={<RenderData />}
							conditionalRowStyles={rowStyles}
							pagination
							paginationServer
							paginationTotalRows={totalRows}
							onChangeRowsPerPage={handlePerRowsChange}
							onSort={handleSort}
							defaultSortField="date"
							onChangePage={handlePageChange}
							defaultSortAsc={false}
							sortServer={true}
						/>
					</div>
				</> : 
				<div className="database-login">
					<h2>Enter the database password:</h2>
					{loginError.length ? <span className="text-red">{loginError}</span> : ``}
					<form onSubmit={(e) => handleSubmit(e)}>
						<input type="password" value={password} onChange={(e)=>setPassowrd(e.target.value)} />
						<button type="submit">Submit</button>
					</form>
				</div>
			}

		</div>
	);
};




export default LeadsDatabase;
