import React, {useEffect, useState} from "react";
import PropTypes, { object } from "prop-types";
import LangSwitcher from "../ui/LangSwitcher";
import Components from '../Components';
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from 'react-markdown';
import { LANGUAGES } from'../../utilities/languages';

const Footer = ({ siblings, classes }) => {



	const [content, setContent] = useState([])
	const [copyright, setCopyright] = useState(``)

	const data = useStaticQuery(graphql`
		query FooterContent {
			allStoryblokEntry(filter: {field_component: {eq: "footer_navigation"}}) {
				edges {
					node {
						lang
						content
						field_copyright_text_string
					}
				}
			}
		}
	`);

	useEffect(() => {
		let activeLang = `default`;
		if(window && typeof window !== `undefined`){
			window.location.pathname.split(`/`).forEach(snippet => {
				if(Object.keys(LANGUAGES).includes(snippet)){
					activeLang = snippet;
				}
			})
		}

		const footerData = data.allStoryblokEntry.edges.find(edge => {
			return edge.node.lang === activeLang;
		});
		const newContent = JSON.parse(footerData.node.content);
		
		setCopyright(footerData.node.field_copyright_text_string)
		setContent(newContent)

	}, [])


	return (
		<footer className={`page-footer ${classes}`}>
			<div className="footer-hero"></div>
			<div className="container text-center text-md-left">
				{content.content &&
					content.content.map((item) => {
						return React.createElement(
							Components(item.component),
							{ key: item._uid, blok: item }
						);
					})
				}
				<div className="row mt-4 d-flex justify-content-between no-break">
					<div className="col-lg-3 col-6 offset-lg-1">
						<a title="SALTO KS IOS app" href="https://itunes.apple.com/us/app/salto-ks/id620313998?mt=8" target="_blank" rel="noopener noreferrer">
							<img className="mb-2" src="https://a.storyblok.com/f/72378/240x80/3d86cc7264/store-ios-2x.png" alt="App store" data-rjs-processed="true" width="120" height="40" />
						</a>
						<a title="SALTO KS android app" href="https://play.google.com/store/apps/details?id=nl.moboa.myclay&amp;hl=en" target="_blank" rel="noopener noreferrer">
							<img className="mb-2" src="https://a.storyblok.com/f/72378/240x80/518b66d54c/store-android-2x.png" alt="Play store" data-rjs-processed="true" width="120" height="40" />
						</a>
					</div>
					<div className="col-lg-3 col-6 offset-lg-5 d-flex d-lg-block justify-content-end">
						<a className="mr-3" title="SALTO Keys as a Service LinkedIn" href="https://www.linkedin.com/company/clay-solutions-b-v-/" target="_blank" rel="noopener noreferrer">
							<i className="fa fa-linkedin" style={{fontSize: `32px`}}></i>
						</a>
						<a className="px-2" title="SALTO Keys as a Service Instagram" href="https://www.instagram.com/claysolutionsamsterdam/" target="_blank" rel="noopener noreferrer">
							<i className="fa fa-instagram" style={{fontSize: `32px`}}></i>
						</a>
					</div>
				</div>
				<div className="row mt-5 text-left">
					<div className="col-lg-8 offset-lg-1 col-sm-12">
						<ReactMarkdown source={copyright} />
					</div>
				</div>
			</div>

		</footer>
	);
};

// TODO: Proptype validation

export default Footer;

Footer.propTypes = {
	siblings: PropTypes.object,
	classes: PropTypes.string
};
