import React, {useState} from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe"


const MailChimpSubscribe = () => {

	const [isChecked, setIsChecked] = useState(false);
	const [email, setEmail] = useState(``);
	const [loading, setLoading] = useState(false);
	const [disable, setDisable] = useState(false);
	const [message, setMessage] = useState(``);

	const URL = `https://saltoks.us3.list-manage.com/subscribe/post?u=ce87827e96279e9dbf7aeb759&amp;id=20282b0048`;

	const isDisabled = () => {
		if(disable){
			return true;
		}
		if(isChecked && email.length > 2){
			return false;
		} else {
			return true;
		}
	}

	return (
		<div className="mailchimp-subscribe-wrapper">
			<h3 className="no-decoration">Stay up to date by subscribing to the SALTO KS monthly newsletter.</h3>

			<MailchimpSubscribe
				url={URL}
				render={({ subscribe, status, message }) => (
					<form onSubmit={(e) => {
						e.preventDefault();
						subscribe({EMAIL: email})
					}}>
						<input type="email" className="email-input" value={email} onChange={e => setEmail(e.target.value)}/>

						<button className="btn btn-primary" disabled={isDisabled()} type="submit">
							{loading ? <img className="spin" src="https://img2.storyblok.com/f/72378/x/7d3d33acf2/rolling-1s-200px.svg" width="24px" /> : 
								<>
									Subscribe
								</>
							}
						</button>

						{message && <span className="info" dangerouslySetInnerHTML={{__html:message}}/> }

						<label className="checkbox-container">
							<input required="required" type="checkbox" value={isChecked} onChange={() => setIsChecked(!isChecked)} />
							<span className="mailchimp-gdpr-message">
								I agree to receive the newsletter and I have read and accepted the <a href="https://www.saltosystems.com/en/legal/legal-data/privacy-policy/" target="_blank"> conditions</a>  of the processing of my personal data by SALTO.
							</span>
						</label>	
					</form>
				)}
			/>
			
		</div>
	)
}

export default MailChimpSubscribe;