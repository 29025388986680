import React, {useState} from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from "react-animate-height";
import {Link} from 'gatsby';
import SbEditable from 'storyblok-react';
import RichText from '../elements/RichText';

const Answer = props => {
	const {
		title,
		content,
		slug
	} = props.blok;

	const [showAnswer, toggleAnswer] = useState(false); 
	const isLink = content.category === `downloads`;

	let parsedSlug = slug && slug.endsWith(`-`) ? slug.slice(0, -1) : slug;

	return (
		<SbEditable content={props.blok}>
			<div className="answer-container">
				{isLink 
					? <DownloadLink title={content.title} link={content.link} />
					: <AnswerTitle 
						title={title}
						open={showAnswer} 
						toggle={()=>toggleAnswer(!showAnswer)}
					/>
				}
				{!isLink && 
					<AnimateHeight animateOpacity={true} height={showAnswer ? `auto` : 0} className="answer-content">
						<div className="inner-wrapper">
							<RichText blok={{content: content}}/>
						</div>
						<Link to={getLink(parsedSlug)} className="info mr-3 mb-2">
							<i className="fa fa-arrow-right text-blue mr-2"></i>
							Go to page
						</Link>
					</AnimateHeight>
				}
				
			</div>
		</SbEditable>
	);
};

const DownloadLink = ({title, link}) => {
	return (
		<a className={`collapsed`} href={getLink(link.cached_url)} target="_blank" rel="noopener noreferrer">
			<div className="title-wrapper">
				<i className="fa fa-arrow-right small mr-1"></i>
				<span className="answer-title">{title}</span>
			</div>
		</a>
	)
}

const AnswerTitle = ({open,title, toggle}) => {
	return (
		<div className={`${open ? `` : `collapsed`}`} onClick={toggle}>
			<div className="title-wrapper">
				<i className="fa fa-chevron-down small mr-1"></i>
				<span className="answer-title">{title}</span>
			</div>
		</div>
	)
}

const getLink = link => {
	if(typeof link === `string`){
		if(link.charAt(0) !== `/`){
			return `/${link}`;
		}
		return `${link}`;
	}
	
	if(link.linktype === `story`){
		if(link.cached_url.charAt(0) !== `/`){
			return `/${link.cached_url}`;
		}
		return `${link.cached_url}`;
	} else {
		return link.cached_url;
	}
};

export default Answer;

Answer.propTypes = {
	blok: PropTypes.shape({
		title: PropTypes.string,
		content: PropTypes.object,
		slug: PropTypes.string
	})
};