import React from 'react';
import PropTypes from 'prop-types';
import Components from '../Components';
import SbEditable from 'storyblok-react';

const Div = (props) => {

	return (
		<SbEditable content={props.blok}>
			<div className={props.blok.classes}>
				{
					props.blok.items.map(item => {
						return React.createElement(
							Components(item.component), 
							{
								key: item._uid, 
								blok: item
							}
						);
					})
				}
			</div>
		</SbEditable>
	);
};

export default Div;

Div.propTypes = {
	blok: PropTypes.shape({
		items: PropTypes.arrayOf(
			PropTypes.shape({
				items: PropTypes.array,
				_uid: PropTypes.string,
				component: PropTypes.string
			})
		),
		classes: PropTypes.string
	}).isRequired
};