import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import { useState, useEffect } from 'react';
import {ImageHelper} from '../../utilities/helpers';
import {format, isSameMonth, isSameYear} from 'date-fns';

const Events = (props) => {

	const [activeVertical, setActiveVertical] = useState(`all`);

	let {events} = props.blok;
	let allVerticals = []
	let verticals = [];


	// Clear array of empty objects
	let parsedEvents = []

	events && events.forEach(event => {

		if(!event.vertical || !event._uid){
			return;
		}

		parsedEvents.push(event);

		if(verticals.find(({value}) => value === event.vertical.toLowerCase())){
			return;
		}

		verticals.push({ 
			value: event.vertical.toLowerCase(),
			label: event.vertical[0].toUpperCase() + event.vertical.substring(1)
		});

	});



	parsedEvents.sort((a,b) => {

		let parsedA = a.editions[0].start ? a.editions[0].start.replace(/ /g, `T`) : ``;
		let parsedB = b.editions[0].start ? b.editions[0].start.replace(/ /g, `T`) : ``;
		
		let aDate = new Date(parsedA);
		let bDate = new Date(parsedB);

		if(aDate > bDate) return -1;
		if(bDate > aDate) return 1;
		return 0;
	});

	// Default value
	verticals.unshift({
		value: `all`,
		label: `Show All`
	});

	allVerticals = verticals.length && verticals.sort((a,b) => a.value.localeCompare(b.value));
	
	const shouldRender = (eventVertical) => {
		if(activeVertical === `all` || eventVertical.length === 0) {
			return true;
		} else {
			return activeVertical.toLowerCase() === eventVertical.toLowerCase();
		}
	};

	return (
		<SbEditable content={props.blok}>
			<>
				<div className="col-12 d-flex justify-content-end align-items-end mb-5">
					{allVerticals.length && <select 
						className="w-100 events-filter"
						name="Cards filter"
						onChange={(e)=>setActiveVertical(e.target.value)}
						defaultValue={`all`}
					>
						<option value="all">{allVerticals[0].label}</option>
						
						{allVerticals.map((option, i) => {
							return i !== 0 && <option key={`option-${i}`} value={option.value}>{option.label}</option>;
						})}
					</select>
					}


				</div>

				{parsedEvents && parsedEvents.map((event, i) => {
					if(shouldRender(event.vertical)){
						return <EventCard key={`post-${i}`} event={event} />;
					}
				})}

			</>
		</SbEditable>
	);
};

export default Events;

const Edition = ({edition}) => {

	let parsedFrom = edition.start ? edition.start.replace(/ /g, `T`) : ``;
	let parsedTo = edition.end ? edition.end.replace(/ /g, `T`) : ``;

	let fromDate = parsedFrom ? new Date(parsedFrom) : ``;
	let toDate = parsedTo ? new Date(parsedTo) : ``;

	let dateString = ``;

	if(isSameYear(fromDate, toDate)){
		if(isSameMonth(fromDate, toDate)){
			dateString = `${format(fromDate, 'dd')} - ${format(toDate, "dd MMMM', 'yyyy")}`;
		} else {
			dateString = `${format(fromDate, 'dd MMM')} - ${format(toDate, "dd MMM', 'yyyy")}`;
		}
	} else {
		dateString = `${format(fromDate, 'dd MMM yyy')} - ${format(toDate, 'dd MMM yyyy')}`;
	}

	return (
		<span className="small d-block">
			{edition.location}, {dateString}
		</span>
	)
}


const EventCard = ({event}) => {

	if(!event.link){
		return null;
	}

	const date = new Date(event.date).toDateString();
	const imageUrl = event.thumb ? ImageHelper.generateUrl(event.thumb[0].image, 400, true, true) : ``;
	const imageBg = {
		backgroundImage: `url('${imageUrl}')`
	};
	
	return (
		<div 
			className="col-12 col-md-6 col-lg-4 d-flex"
		>
			<div className="story-block w-100 d-flex flex-column">
				<div className="header">
					<h3 className="no-decoration m-0">
						{event.event_name}
					</h3>

					{event.editions && event.editions.map(edition => {
						return <Edition edition={edition} />
					})}
			
					<span className="small text-grey d-flex">
						{event.vertical}
					</span>
				</div>
				<a href={event.link.cached_url} target="_blank" rel="noopener noreferrer" className="w-100 image" style={imageBg}>
				</a>
				<h6 className="story-title">
					{event.description}
				</h6>

				<a href={event.link.cached_url} target="_blank" rel="noopener noreferrer" className="info mt-auto mb-0">
					<i className="fa fa-arrow-right text-blue"></i>
					{event.link_text}
				</a>
			</div>
		</div>
	);
};

EventCard.propTypes = {
	event: PropTypes.shape({
		date: PropTypes.string,
		location: PropTypes.string,
		event_name: PropTypes.string,
		vertical: PropTypes.string,
		description: PropTypes.string,
		thumb: PropTypes.array,
		link: PropTypes.shape({
			cached_url: PropTypes.string
		}),
		link_text: PropTypes.string
	}).isRequired
};

Events.propTypes = {
	blok: PropTypes.shape({
		events: PropTypes.array
	}).isRequired
};
