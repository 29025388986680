import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import ReactPlayer from 'react-player';

const Video = (props) => {
	return (
		<SbEditable content={props.blok}>
			{ props.blok.url && <ReactPlayer className="mx-auto" controls={true} playing={props.blok.autoplay || false} url={props.blok.url} /> }
		</SbEditable>
	);
};

export default Video;

Video.propTypes = {
	blok: PropTypes.shape({
		url: PropTypes.string,
		autoplay: PropTypes.bool
	}).isRequired
};