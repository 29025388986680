import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';

const EmailInput = (props) => {


const {blok, register, errors} = props;


	const {
		label,
		name,
		zoho_label,
		required
	} = blok;

	const validateUrl = (value) => {
		if(!value.length) {
			return true;
		}
		if(!value.startsWith(`http`) && !value.startsWith(`https`)){
			value = `https://` + value;
		}
		return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
	};


	return (
		<SbEditable content={blok}>
			<>
				<label>
					{label}{required ? `*` : ` (Optional)`}
					<input 
						type="text" 
						id={name}
						name={name}
						ref={
							register({
								validate: val => validateUrl(val)
							})
						}
					/>
				</label>
				<span 
					role="alert" 
					className={
						`validation-error small ${errors[name] ? `show` : `hide`}`
					}>
						Please type in a valid website address
				</span>
			</>
		</SbEditable>
	);
};

export default EmailInput;

EmailInput.propTypes = {
	blok: PropTypes.shape({
		classes: PropTypes.string
	})
};