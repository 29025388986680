import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Image from './Image';

const PlayButton = (props) => {
	props.blok.classes = `img-fluid center-play`;
	return (
		<SbEditable content={props.blok}>
			<div className="video-player">
				<Image blok={props.blok} />
			</div>
		</SbEditable>
	);
};

export default PlayButton;

PlayButton.propTypes = {
	blok: PropTypes.shape({
		classes: PropTypes.string
	})
};