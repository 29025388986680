import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Components from '../Components';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import {navigate} from 'gatsby';
import RichText from '../elements/RichText';

const FormPardot = (props) => {
	const {
		items,
		button_text,
		success_message,
		error_message
	} = props.blok;


	const methods = useForm();
	const { handleSubmit } = methods;
	const [submitStatus, setSubmitStatus] = useState({ success: null, message: ``, show: false });
	const [disableSend, setDisableSend] = useState(false);
	const [loading, setLoading] = useState(false);
	const [source, setSource] = useState(``);

	useEffect(()=> {
		try {
			const globalDocument = typeof document == `undefined` ? null : document;
			if(globalDocument && globalDocument.cookie.length){
				const cookie = globalDocument.cookie.replace(/(?:(?:^|.*;\s*)source\s*\=\s*([^;]*).*$)|^.*$/, `$1`);
				if(cookie.length){
					setSource(cookie);
				}
			}
		} catch(e) {
			console.log(e);
		}
	}, []);

	const onSubmit = async data => {
		setDisableSend(true);
		setLoading(true);
		if(source.length){
			data.utm_source = source;
		}

		

		let result;
		let success = false;

		let parsedData = {};
		
		Object.keys(data).map(item => {
			if(data[item] === `DEFAULT` || !data[item]){
				parsedData[item] = ``;
			} else {
				parsedData[item] = data[item];
			}
		});

		// return;
		try {
			result = await axios({
				method: `POST`,
				url: `https://saltoks.com/.netlify/functions/pardot`,
				data: {
					type: `pardot-quote`,
					info: parsedData,
					sendToZoho: true
				},
				headers: {
					'content-type': `application/json`
				}
			});
		} catch(e){
			console.log(e);	
		}

		success = result && result.status === 200;

		setLoading(false);
		if (!success) {
			setSubmitStatus({ success, message: error_message, show: true });
			setDisableSend(false); // So that the user can try again
		} else {
			navigate(`/thank-you`);
		}

	};

	return (
		<SbEditable content={props.blok}>

			<form className="w-100 form-wrapper wpcf7" onSubmit={handleSubmit(onSubmit)}>
				<div className="row px-0">

					{items.map(item => {
						if(item.component === `small_title`){
							return <div key={item._uid} className="col-12"><h3 className="px-0 mb-3 mx-0 no-decoration col-12 ">{item.small_title}</h3></div>;
						} else {
							return (
								<div 
									key={item._uid} 
									className={
										`${item.name === `country` ? `d-flex justify-content-between` : `d-block`} input-wrapper ${item.name} ${item.half_width ? `col-md-6` : `col-12`}`
									}
								>
									{
										React.createElement(
											Components(item.component),
											{
												key: item._uid,
												blok: item,
												register: methods.register,
												errors: methods.errors,
												setValue: methods.setValue,
												pardot: true
											}
										)
									}
								</div>
							);
						}
					})}

					{props.blok.terms_and_conditions.length && 
						<div className="d-block input-wrapper col-12">
							<div className="tos-text">
								<input 
									type="checkbox"
									id="tos"
									name="tos"
									value="tos"
									ref={methods.register({ required: true })}
								/>
								<label htmlFor="tos">
									<RichText blok={{content: props.blok.terms_and_conditions}} />
								</label>
							</div>
							<span 
								role="alert" 
								className={
									`validation-error small ${methods.errors[`tos`] ? `show` : `hide`}`
								}>
										This field is required.
							</span>
						</div>
					}
					<div className="d-block input-wrapper col-12">
						<div className="tos-text">
							<input 
								type="checkbox"
								id="commercial-comms"
								name="commercial-comms"
								value="commercial-comms"
								ref={methods.register()}
							/>
							<label htmlFor="tos">
								<RichText blok={{content: props.blok.commercial_communication}} />
							</label>
						</div>
					</div>
					<div className="d-block input-wrapper col-12">
						<div className="tos-text">
							<input 
								type="checkbox"
								id="data-sharing"
								name="data-sharing"
								value="data-sharing"
								ref={methods.register()}
							/>
							<label htmlFor="tos">
								<RichText blok={{content: props.blok.data_sharing}} />
							</label>
						</div>
					</div>

					<button className="btn w-100 btn-primary" disabled={disableSend} type="submit">
						{loading ? <img className="spin" src="https://img2.storyblok.com/f/72378/x/7d3d33acf2/rolling-1s-200px.svg" width="24px" /> : 
							<>
								{button_text || `Submit`}
							</>
						}
					</button>

					{submitStatus.show && 
						<span className="d-block mt-4">
							{submitStatus.success ? <i className="fa fa-check mr-2" style={{color: `green`}}></i> : <i style={{color: `red`}} className="mr-2 fa fa-exclamation-triangle"></i>}
							{submitStatus.message}
						</span>
					}
				</div>

			</form>
		</SbEditable>
	);
};

export default FormPardot;
