import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Components from '../Components';

const CardSection = (props) => {

	return (
		<SbEditable content={props.blok}>
			<div className={`${props.blok.classes} ${props.blok.bordered ? `bordered` : ``}`}>
				{props.blok.body.map(item=> {
					return React.createElement(
						Components(item.component), 
						{
							key: item._uid, 
							blok: item
						}
					);
				})}
			</div>
		</SbEditable>
	);
};

export default CardSection;

CardSection.propTypes = {
	blok: PropTypes.shape({
		classes: PropTypes.string,
		bordered: PropTypes.bool,
		body: PropTypes.arrayOf(
			PropTypes.shape({
				item: PropTypes.shape({
					component: PropTypes.string.isRequired,
					_uid: PropTypes.string.isRequired,
				})
			})
		)
	}).isRequired
};