import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import {ImageHelper} from '../../utilities/helpers';
import Popup from 'reactjs-popup';
import SVG from 'react-inlinesvg';
import Header from "./Header";
import Footer from "./Footer";
import "../../styles/general.scss";

const Layout = ({children, headerStyle, nav, siblings, layout, full, footer, meta = false}) => {

    const [showPopup, setShowPopup] = useState(false);

    const closePopup = () => {
        setShowPopup(false);
        localStorage.setItem(`popup-closed`, `true`);
    }

    useEffect(() => {
        let previouslyClosed = localStorage.getItem(`popup-closed`);
        setShowPopup(!previouslyClosed);
    }, []);


    const isFullWidth = () => {
        return layout === `full-width` || full;
    };

    const imageUrl = `//a.storyblok.com/f/72378/2850x1434/746861d2b7/salto-image.png`;

    const dimensions = {
        w: 1200,
        h: 630
    };

    const logo = ImageHelper.generateUrl(`//a.storyblok.com/f/72378/184x56/a01da24387/logo-color.png`);

    if (!meta) {
        meta = {
            headline: ``,
            lang: `en`,
            title: `SALTO KS | Cloud-Based Smart Locks, Wireless Access Control`,
            description: `SALTO KS, SALTO Keys as a Service - Wireless Access Control For Your Business. Cloud-Based, Real-Time And On-The-Go Smart Lock Solution. Go Keyless Now!`,
            keywords: `Access control, locks, wireless, cloud-based, keyless, entry, real-time`,
            full_link: `https://saltoks.com/`,
            schema: [],
            other: {
                first_published_at: ``,
                published_at: ``
            }
        };
    }


    const pageImageUrl = meta.schemaImg || imageUrl;

    const schema = !meta.no_schema && [
        {
            '@type': `Article`,
            'headline': meta.headline,
            'description': meta.description,
            'datePublished': (meta.other && meta.other.first_published_at) || ``,
            'dateModified': (meta.other && meta.other.published_at) || ``,
            'publisher': {
                '@type': `Organization`,
                'name': `SALTO KS`,
                'logo': {
                    '@type': `ImageObject`,
                    'url': logo
                }
            },
            'mainEntityOfPage': {
                '@type': `WebPage`,
                '@id': `https://saltoks.com`
            },
            'author': {
                '@type': `Organization`,
                'name': `SALTO KS`
            },
            'image': {
                '@type': `ImageObject`,
                'url': ImageHelper.generateCardUrl(pageImageUrl, {w: 300, h: 300}),
                'width': 300,
                'height': 300
            }
        },
    ];

    if (meta.schema) {
        schema.push(...meta.schema);
    }

    const socialCardImg = ImageHelper.generateCardUrl(pageImageUrl, dimensions);
    let siblingKeys;
    if (siblings) {
        siblingKeys = Object.keys(siblings);
    }

    const pageLink = meta.full_link === `https://saltoks.com/home` ? `https://saltoks.com` : meta.full_link;

    const htmlMeta = meta.amp ? [`amp`] : [``];

    return (
        <>
            <Helmet defer={false}>
                <html lang={meta.lang}/>
                <title>{meta.title}</title>
                <meta charset="utf-8"/>

                {/* TEMPORARY */}

                <meta name="description" content={meta.description}/>
                <meta name="keywords" content={meta.keywords}/>

                { /* SOCIAL */}
                <meta property="og:locale" content={meta.lang}/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={meta.title}/>
                <meta property="og:description" content={meta.description}/>
                <meta property="og:url" content={pageLink}/>
                <meta property="og:site_name" content="SALTO KS"/>
                <meta property="og:image" content={socialCardImg}/>
                <meta name="twitter:image:alt" content="SALTO KS | Cloud-Based Smart Locks, Wireless Access Control"/>
                <meta name="twitter:card" content="summary_large_image"/>

                {siblingKeys && siblingKeys.map(key => {
                    <link rel="alternate" href="saltoks.com/{siblingKeys[key].full_slug]}"
                          hrefLang={siblingKeys[key].lang} key={siblingKeys[key].lang}/>;
                })}

                { /* OneTrust Cookies Consent Notice start */}
                <script src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js" data-document-language="true"
                        type="text/javascript" charSet="UTF-8"
                        data-domain-script="e28cfc29-0be9-4389-995e-70cc87624d67"></script>
                <script type="text/javascript">
                    {`function OptanonWrapper() { }`}
                </script>

                { /* SCHEMA */}
                <script type="application/ld+json">{JSON.stringify(schema)}</script>
            </Helmet>
            {/* <Popup className="px-5 d-flex" open={showPopup} closeOnDocumentClick onClose={closePopup}>
			 	<button onClick={closePopup} className="close p-3 pr-4"><span>×</span></button>
			 	<div className="m-5 py-3 px-5 text-center">
			 		<SVG src="https://a.storyblok.com/f/72378/x/bf4923138a/salto-ks-logo.svg" width={196} height={60} className="popup-logo"/>
			 		<h2 className="mt-5 text-black">We've got news!</h2>
			 		<p className="mt-4 intro">This page soon will be removed. All information about <span className="text-primary">SALTO KS</span> and its product portfolio can now be found on <a target="_blank" rel="noopener noreferrer" href="https://saltosystems.com/saltoks">SaltoSystems.com/saltoks</a>.</p>
			 		<p className="mt-3 intro">For a deep dive into the team that's behind <span className="text-primary">SALTO KS</span>, please visit <a className="text-clay-color" target="_blank" rel="noopener noreferrer" href="https://my-clay.com">my-clay.com</a>.</p>
			 	</div>
			 </Popup> */}
            <Header
                headerStyle={headerStyle}
                nav={nav}
                siblings={siblings}
            />
            <div className={isFullWidth() ? `` : `container`}>
                {children}
            </div>
            <Footer classes={footer}/>
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    headerStyle: PropTypes.string,
    nav: PropTypes.object,
    siblings: PropTypes.object,
    full: PropTypes.bool,
    layout: PropTypes.string,
    footer: PropTypes.string,
    meta: PropTypes.bool
};

export default Layout;
