import React, { useState } from 'react';
import PropTypes from 'prop-types';

const EndpointLayout = ({ blok }) => {
	const [activeTab, setActiveTab] = useState(0);
	const endpoint = blok.endpointData;

	let isCore = true;
	return (
		<>
			<div className="col-md-1 line-numbers mt-2">
				<pre>{[...Array(100)].map((e, i) => <>{i}<br /></>)}</pre>
			</div>

			{Object.keys(endpoint).length !== 0 && 
				<>
					<div className="tab-header mt-2">
						<a onClick={() => setActiveTab(0)} className={`btn header-response mr-2 ${activeTab === 0 ? `btn-primary` : `btn-default`}`}>request</a>
						{Object.keys(endpoint.responses).map((code) => (
							<a key={code} onClick={() => setActiveTab(code)} className={`btn header-response mr-2 ${activeTab === code ? `btn-primary` : `btn-default`}`}>Response {code}</a>
						))}
					</div>
					<div className="tab-body">
						{activeTab === 0 ?
							(
								<pre className="col-md-11">
									Endpoint: <span>{blok.endpoint.endpoint}</span><br />
									Method: <span>{blok.endpoint.method.toUpperCase()}</span><br />
									
									{endpoint.hasOwnProperty(`parameters`) && endpoint.parameters.length > 0 && <>
									Parameters: <br />
										{endpoint.parameters.map((p) =>
											isCore ? renderRequestParameterCore(p) : renderRequestParameter(p)
										)}

										{isCore && endpoint.requestBody ? renderRequestBodyCore(endpoint.requestBody.content) : null}

									</>}
								</pre>
							) : responseDetails(endpoint.responses[activeTab], isCore)
						}
					</div>
				</>
			}
		</>
	);
};

EndpointLayout.propTypes = {
	blok: PropTypes.shape({
		endpoint: PropTypes.shape({
			endpoint: PropTypes.string,
			method: PropTypes.string
		}),
		endpointData: PropTypes.shape({
			endpoint: PropTypes.string,
			method: PropTypes.string,
			parameters: PropTypes.array,
			responses: PropTypes.object
		})
	})
};

const renderRequestBodyCore = (content) => {
	let props = content[`application/json`].schema;


	return props && (
		<>
			<span>
				<span className="text-white">
						Body (application/json):<br/>
						&#123;
				</span>
				<br/>
				{renderObj(props)}
				<br/>
				<span className="text-white">&#125;</span>
			</span>
		</>		
	);
};

const renderRequestParameterCore = (param, i) => {
	return (
		<React.Fragment key={`parameters${i}`}>
			<span>
				<span className="text-white">{param.in} &#123;</span>
				<span>
					{tooltip(param.name, param)}
					{param.name}
				</span>
				<span className="text-white">&#125; ({param.schema.type})</span>
			</span>
			<br/>
		</React.Fragment>
	);
};

const renderRequestParameter = (param, i) => {

	return param.schema 
		? (
			<React.Fragment key={`parameters${i}`}>
				<span>
					<span className="text-white">
						Body (application/json):<br/>
						&#123;
					</span>
					<br/>
					{renderObj(param.schema)}
					<br/>
					<span className="text-white">&#125;</span>
				</span>
			</React.Fragment>
		)
		: (
			<React.Fragment key={`parameters${i}`}>
				<span>
					<span className="text-white">{param.in} &#123;</span>
					<span>
						{tooltip(param.name, param)}
						{param.name}
					</span>
					<span className="text-white">&#125; ({param.type})</span>
				</span>
				<br/>
			</React.Fragment>
		)
	;
		
};

const responseDetails = (response, isCore) => {
	let hasContent;
	if(isCore) {
		let props = response.content && response.content[`application/json`].schema.properties
		hasContent = props && Object.keys(props).length > 0;
	} else {
		hasContent = response.schema && Object.keys(response.schema).length > 0;
	}
	
	let props, propsArray;
	if(hasContent){

		if(isCore){
			props = response.content[`application/json`].schema.properties;
		} else {
			props = response.schema.properties;
		}

		propsArray = Object.keys(props);
	}
	
	return (
		<pre className="col-md-11">

			{hasContent ? 
				<>
					Response Content Type (application/json):
					<br />
					&#123;
					<br />
					<span className="prop-wrapper">
						{propsArray.map((key, i) => 
							renderProperty(
								key, 
								props[key], 
								i !== propsArray.length-1
							)
						)}
					</span>
					<br />				
					&#125;
				</>

				:
				<span>No content</span>
			}
		</pre>
	);
};

const renderProperty = (key, content, comma) => {

	let newContent;
	if(content.allOf){
		newContent = content.allOf[0];
	} else {
		newContent = content;
	}
	if (key !== `type`) {
		return (
			<>
				<span><span>&quot;{key}&quot;</span>{tooltip(key, newContent)}</span>
				<span className="text-white">: </span>

				{newContent.type === `object` && 
					<>
						<span className="text-white">&#123;</span>
						<br />
						{renderObj(newContent)}
						<br />
						<span className="text-white">&#125;</span>
					</>
				}

				{(newContent.type === `array` || newContent.type === `enum`) && 
					<>	
						<span className="text-white">[</span>
						<br />
						{renderArray(newContent)}
						<br />
						<span className="text-white">]</span>
					</>
				}

				{
					newContent.type !== `object` 
					&& newContent.type !== `array` 
					&& <span>&quot;{newContent.type}&quot;</span>
				}

				{comma && <span className="text-white">,</span>}

				<br />
			</>
		);
	}
};

const renderObj = obj => {
	let objProps;
	if(obj.properties) {
		objProps = Object.keys(obj.properties);
	}
	return (
		<>
			{objProps &&
				<span className="prop-wrapper">
					{objProps.map((key, i) => {
						return (
							<>
								{renderProperty(
									key, 
									obj.properties[key],
									i !== objProps.length - 1 
								)}
							</>
						);
					})}
				</span>
			}
		</>
	);
};

const tooltip = (key, content) => (
	<span className="tooltip">
		<h6>{key}</h6>
		<span>
			{(content.type || content.schema.type) && <>type: {content.type ? content.type : content.schema.type}</>}
			{content.format && <>, format: {content.format}</>}
		</span>
		<span></span>
		<span className="text-blue"></span>
		<p>{content.description}</p>
	</span>
);

const renderArray = arr => {
	return (
		<>
			{arr.items.type === `object` 
				? <span className="prop-wrapper">
					<span className="text-white">&#123;</span><br/>
					{renderObj(arr.items)}
					<br/><span className="text-white">&#125;</span>
				</span>
				: <span className="prop-wrapper">
					"{arr.items.type}"
				</span>
			}
		</>
	);
};

export default EndpointLayout;
