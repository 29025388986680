import React, {useState} from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Components from '../Components';
import Collapse from '@kunukn/react-collapse';

const CollapseWrapper = (props) => {
	const [open, toggleCollapse] = useState(false);
	return (
		<SbEditable content={props.blok}>
			<div className="mb-3">
				<button 
					className={`${open ? 'expanded' : 'collapsed'} clear`} 
					onClick={()=>toggleCollapse(!open)}
				>
					<i className="fa fa-angle-down text-blue mr-1"></i>
					{props.blok.toggle}
				</button>
				<Collapse isOpen={open} className="collapse-css-transition mt-3">
					{
						props.blok.content.map(item =>{
							return React.createElement(
								Components(item.component), 
								{
									key: item._uid, 
									blok: item
								}
							);
						})
					}
				</Collapse>
			</div>
		</SbEditable>
	);
};

export default CollapseWrapper;
