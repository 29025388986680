import React, { useState } from "react";
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Components from '../Components';
import SubTitle from '../elements/SubTitle';
import Paragraph from '../elements/Paragraph';
import Image from '../elements/Image';

const Features = (props) => {

	// Set first feature as active
	const [activeFeature, setActiveFeature] = useState(props.blok.features[0]._uid);

	return (
		<SbEditable content={props.blok}>
			<div className="col-12 col-md-6 col-lg-4 offset-lg-1 ">

				{/* Section header */}
				{props.blok.header.map(item => {
					return React.createElement(
						Components(item.component), 
						{
							key: item._uid, 
							blok: item
						}
					);
				})}

				{/* Feature description */}
				{props.blok.features.map((feature, i) => {
					const show = activeFeature === feature._uid;
					return (
						<div 
							className={`highlight ${show ? `active` : ``}`}  
							key={feature._uid}
							onMouseOver={()=>setActiveFeature(feature._uid)}
						>
							<div className="icon">
								<i className="fa fa-check"></i>
							</div>
							<div className={`highlight-content ${i !== props.blok.features.length-1 ? `border-bottom`: ``}`}>
								<SubTitle blok={{sub_title: feature.title, classes: `no-decoration`}} />
								<Paragraph blok={{paragraph: feature.description}} />
							</div>
						</div>
					);
				})}

				{/* Links */}
				<div className="links">
					{props.blok.links.map(link => {
						return React.createElement(
							Components(link.component), 
							{
								key: link._uid, 
								blok: link
							}
						);
					})}
				</div>

			</div>
			
			{/* Feature images */}
			<div className="col-12 col-md-6 col-lg-6 offset-lg-1 text-center d-none d-md-block">
				{props.blok.features.map((feature) => {
					const show = activeFeature === feature._uid;
					return show ? (
						<div 
							className={`highlight-slide ${activeFeature === feature._uid ? `active` : ``}`} 
							key={feature._uid}
						>
							{feature.images.map((img) => {
								return <Image key={img._uid} blok={img} />;
							})}
						</div>
					) : ``;
				})}
			</div>

			
		</SbEditable>
	);
};
export default Features;

Features.propTypes = {
	blok: PropTypes.shape({
		header: PropTypes.arrayOf(PropTypes.shape),
		links: PropTypes.arrayOf(PropTypes.shape),
		features: PropTypes.arrayOf(
			PropTypes.shape({
				_uid: PropTypes.string,
				title: PropTypes.string,
				description: PropTypes.string,
				images: PropTypes.arrayOf(
					PropTypes.shape({
						_uid: PropTypes.string,
						image: PropTypes.string,
						classes: PropTypes.string
					})
				)
			})
		)
	})
};
