import React from 'react';
import PropTypes from 'prop-types';
import Components from '../Components';
import SbEditable from 'storyblok-react';

const Div = (props) => {

	return (
		<SbEditable content={props.blok}>
			<div 
				className={props.blok.classes} 
				dangerouslySetInnerHTML={{__html: props.blok.content}} 
			/>
		</SbEditable>
	);
};

export default Div;

Div.propTypes = {
	blok: PropTypes.shape({
		content: PropTypes.string,
		classes: PropTypes.string
	}).isRequired
};