import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Image from './Image';

const Quote = (props) => {
	return (
		<SbEditable content={props.blok}>
			<div className={`quote-wrapper ${props.blok.classes}`}>
				<Image blok={props.blok.quotation_mark[0]} />
				<blockquote>
					{props.blok.quote}
				</blockquote>
				<div className="quote-info mt-4">
					
					{props.blok.author && <Author link={props.blok.author_link} author={props.blok.author}/>}
					{props.blok.position && <span className="small">{props.blok.position}</span>}
				</div>
			</div>
		</SbEditable>
	);
};

const Author = ({link, author}) => {
	if(link && link.cached_url){
		return (
			<a href={link.cached_url} target="_blank" rel="noopener noreferrer"> <span className="strong small">{author}</span></a>
		);
	} else {
		return (
			<span className="strong small">{author}</span>
		);
	}
};

export default Quote;

Quote.propTypes = {
	blok: PropTypes.shape({
		quotation_mark: PropTypes.array,
		quote: PropTypes.string,
		author: PropTypes.string,
		position: PropTypes.string,
		classes: PropTypes.string
	}).isRequired
};