import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';

const Button = (props) => {
	let classes = `btn`;

	let preventDefault = false;
	if(props.blok.shadow){
		classes = `${classes} btn-shadow`;
	}
	if(props.blok.style){
		classes = `${classes} ${props.blok.style}`;
	}

	let external = props.blok.link.linktype === `url`;

	const linkProps = {
		className: `${classes} ${props.blok.classes}`,
	};

	if(props.blok.link.cached_url.length){
		linkProps.href = getUrl(props.blok.link.cached_url, external);
	}

	if(!props.blok.link.url.length && !props.blok.link.cached_url.length){
		linkProps.href = `#`;
		linkProps.onClick = (e) => {
			e.preventDefault();
		}
	}

	if(external || props.blok.link.new_tab){
		linkProps.target = `_blank`;
		linkProps.rel = `noopener noreferrer`;
	}
	
	return (
		<SbEditable content={props.blok}>
			<>
				<a {...linkProps}>
					{props.blok.text}
				</a>
			</>
		</SbEditable>
	);
};


const getUrl = (path, external) => {
	if(path.charAt(0) !== `/` && !external){
		return `/${path}`
	} 
	return path;
}

export default Button;

Button.propTypes = {
	blok: PropTypes.shape({
		shadow: PropTypes.boolean,
		style: PropTypes.string,
		classes: PropTypes.string,
		link: PropTypes.shape({
			cached_url: PropTypes.string,
		}),
		text: PropTypes.string.isRequired
	}).isRequired
};
