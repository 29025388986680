import React, {useState} from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { useEffect } from 'react';


const PhoneField = (props) => {

	const {blok, setValue, register, errors} = props;
	const {
		label,
		required
	} = blok;

	const [telephone, setTelephone] = useState(``);
	const [prefix, setPrefix] = useState(``);
	const [rawTelephone, setRawTelephone] = useState(``)

	const phoneUpdate = (value, data) => {

		let prefix = data.dialCode;
		let nr = data.dialCode ? value.slice(data.dialCode.length) : ``;
		setTelephone(value);
		setPrefix(prefix);
		setValue(`telephone`, nr);
		setValue(`prefix`, prefix)
	};

	useEffect(()=>{
		register({name: `telephone`}, {required: required});
		register({name: `prefix`})
	}, [register, required]);

	
	return (	
		<SbEditable content={blok}>
			<>
				
				<label>
					{label}{required && `*`}
				</label>
				<ReactPhoneInput 
					defaultCountry={`sg`}
					value={telephone}
					onChange={(val, data)=> phoneUpdate(val, data)}
					name="telephone"
				/>
				<span 
					role="alert" 
					className={
						`validation-error small ${errors[`telephone`] ? `show` : `hide`}`
					}>
										This field is required.
				</span>
			</>
		</SbEditable>
	);
};

export default PhoneField;

PhoneField.propTypes = {
	blok: PropTypes.shape({
		label: PropTypes.string,
		name: PropTypes.string,
		required: PropTypes.bool
	}),
	setValue: PropTypes.func,
	register: PropTypes.func,
	errors: PropTypes.object
};