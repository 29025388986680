import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Components from '../Components';

const LinkWrapper = (props) => {

	let link = ``;

	if(props.blok.link.cached_url.length){
		link = getUrl(props.blok.link);
	};
	
	return (
		<SbEditable content={props.blok}>
			<>
				<a href={link}>
					{props.blok.content.map(item => {
						return React.createElement(
							Components(item.component), 
							{
								key: item._uid, 
								blok: item
							}
						);
					})}
				</a>
			</>
		</SbEditable>
	);
};


const getUrl = (link) => {
	if(link.linktype === `url`){
		return link.cached_url;
	} else if(link.cached_url.charAt(0) !== `/`){
		return `/${link.cached_url}`
	} 
}

export default LinkWrapper;

LinkWrapper.propTypes = {
	blok: PropTypes.shape({
		link: PropTypes.shape({
			cached_url: PropTypes.string,
		})
	}).isRequired
};
