const prepareUrl = (path, lang) => {

	if(!path.length){
		return;
	}

	if(path.startsWith(`https://`)){
		return path;
	}
	
	if ((lang && lang === `default`) || path.charAt(0) !== `/`) {
		return path === `home` ? `/` : `/${path}`;
	}
	return path;
};

const ImageHelper = {

	/* 
	* Example string: https://a.storyblok.com/f/39898/3310x2192/e4ec08624e/demo-image.jpeg
	* The structure of the url is always the same : domain/f/spaceID/dimensions/id/file-name.format
	* So we split the link at the '/' character, and the 5th item holds the dimensions of the file
	* To get the format, we take everything after the last dot (.) in the url
	*/

	getOriginalDimensions: path => {
		if(!path.includes(`storyblok`)){
			return false;
		}
		let sizeString = path.split(`/`)[5];
		const dimensions = sizeString.split(`x`);
	
		return {
			width: dimensions[0],
			ratio: dimensions[0] / dimensions[1]
		};
	},
	
	
	getFormat: path => {
		const dotPosition = path.lastIndexOf(`.`);
		return path.slice(dotPosition + 1, path.length);
	},
	
	generateUrl: (path, dimension, webp, byHeight) => {
		if(!path.includes(`storyblok`)){
			return path;
		}
		let options = ``;
		
		if(dimension){
			options = byHeight ? `0x${dimension}` : `${dimension}x0`;
		}
	
		if(webp){
			options = `${options}/filters:format(web)`;
		}
		
		// const imageService = `https://assets.saltoks.com/`;
		// const imgPath = path.replace(`//a.storyblok.com`, `https://a.storyblok.com`);
	
		return path + `${options ? `/m/` : `` }` + options;
	},

	generateCardUrl: (path, dimensions) => {
		const options = `/m/${dimensions.w}x${dimensions.h}/filters:format(jpg)`;

		const imageService = `https://a.storyblok.com/`;
		const imgPath = path.replace(`//a.storyblok.com`, `https://a.storyblok.com`);
		return imgPath + options;

	}
};


export {
	prepareUrl,
	ImageHelper
};
