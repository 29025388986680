import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Answer from './Answer';
import { useStaticQuery, graphql } from 'gatsby';
import FuzzySearch from 'fuzzy-search';
import useDebounce from './use-debounce';

const CommonQuestions = props => {
    const { search_text, common_questions_text } = props.blok;

    const questionsData = useStaticQuery(graphql`
        query CommonQuestionsQuery {
            allStoryblokEntry(
                filter: {
                    field_component: { eq: "page_answer" }
                    lang: { eq: "default" }
                }
            ) {
                edges {
                    node {
                        content
                        field_title_string
                        full_slug
                        id
                        field_common_boolean
                        field_tags_string
                    }
                }
            }
        }
    `);

    const allQuestionsData = questionsData.allStoryblokEntry.edges;

    const allQuestions = allQuestionsData.map(question => {
        const contentData = JSON.parse(question.node.content);

        return {
            content: contentData.content,
            title: contentData.title,
            key: question.node.id,
            slug: question.node.full_slug,
            common: question.node.field_common_boolean,
            tags: question.node.field_tags_string || ``,
        };
    });

    const searcher = new FuzzySearch(allQuestions, [`title`, `tags`], {
        sort: true,
    });

	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce(searchTerm, 600);

	useEffect(
		() => {
		  if (debouncedSearchTerm) {
			doSearch(debouncedSearchTerm)
		  }
		},
		[debouncedSearchTerm]
	  );

    const doSearch = term => {
        const termLength = term.length;
        if (termLength === 0) {
            setSearchActive(false);
            setQuestionslist(commonQuestions);
            return;
        }
        if (termLength < 3) {
            return;
        }

        if (window.dataLayer) {
            window.dataLayer.push({
                event: `searchCompleted`,
                searchTerm: term,
            });
        }

        const searchResult = searcher.search(term);
        setSearchActive(true);
        setQuestionslist(searchResult.filter((result, i) => i < 10));
    };

    const commonQuestions = allQuestions.filter(question => question.common);
    const [questionsList, setQuestionslist] = useState(commonQuestions);
    const [searchActive, setSearchActive] = useState(false);

    return (
        <SbEditable content={props.blok}>
            <>
                <div>
                    <h3 className="no-decoration">{search_text}</h3>
                    <div className="d-flex mb-3 py-2">
                        <input
                            onChange={e => setSearchTerm(e.target.value)}
                            type="text"
                            placeholder="Search for answers"
                            className="form-input"
                        />
                    </div>
                </div>
                <h3 className="no-decoration mb-4">
                    {searchActive ? `Search results:` : common_questions_text}
                </h3>

                {questionsList.length ? (
                    renderQuestions(questionsList)
                ) : (
                    <span>There are no results matching your criteria.</span>
                )}
            </>
        </SbEditable>
    );
};

export default CommonQuestions;

const renderQuestions = list => {
    return (
        <>
            {list.map(question => (
                <Answer blok={question} key={question.key} />
            ))}
        </>
    );
};

CommonQuestions.propTypes = {
    blok: PropTypes.shape({
        search_text: PropTypes.string,
        common_questions_text: PropTypes.string,
    }).isRequired,
};
