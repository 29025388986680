import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MailChimpSubscribe from '../elements/MailChimpSubscribe';
const NewsletterArchive = ({ blok }) => {
	return (
		<div className="newsletter">
			<div className="styled-list newsletter-list">
				<ul>
					{blok.newsletters && blok.newsletters.map(newsletter => {
						return <Newsletter key={newsletter._uid} item={newsletter} />
					})}
				</ul>
			</div>

			{ blok.show_subscribe && <MailChimpSubscribe/> }
		</div>
	);
};

const Newsletter = ({item}) => {
	return (
		<li>
			<a className="newsletter" target="_blank" rel="noopener noreferrer" href={item.file.filename}>
				<div>
					<span className="list-title newsletter-title">
						{item.title}
					</span>
				</div>
				<div className="info">
					<i className="fa fa-download text-blue mr-2"></i>
					<span className="d-none d-md-inline text-black">Download</span>
				</div>
			</a>
		</li>
	);
};

NewsletterArchive.propTypes = {
	blok: PropTypes.shape({
		show_subscribe: PropTypes.bool,
		newsletters: PropTypes.array
	})
};

export default NewsletterArchive;
