import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Components from '../Components';

const Card = (props) => {
	return (
		<SbEditable content={props.blok}>
			<div className="card-icon h-100">
				<div className="card-wrapper">
					{props.blok.card_section.map(section => {
						return React.createElement(
							Components(section.component), 
							{
								key: section._uid, 
								blok: section
							}
						);
					})}	
				</div>
			</div>
		</SbEditable>
	);
};

export default Card;

Card.propTypes = {
	blok: PropTypes.shape({
		card_section: PropTypes.arrayOf(
			PropTypes.shape({
				component: PropTypes.string.isRequired,
				_uid: PropTypes.string.isRequired
			})
		)
	}).isRequired
};