// Generate array of [100, 200, ..., 2400]
const IMAGE_SIZES = new Array(24).fill().map((item, i) => ++i * 100);

const UNSUPPORTED_IMAGE_FORMATS = [
	`svg`,
	`gif`
];

const BUSINESS_TYPES = [
	`Coworking Space`,
	`Coliving Space`,
	`Student Housing`,
	`Retail`,
	`Hospitality`,
	`-------`,
	`Airport / Airfield`,
	`Bank`,
	`Convention Center`,
	`Corporate Building / Office Building`,
	`Cruise Ship`,
	`Delivery Services`,
	`Multi-Family Residential`,
	`Public Housing`,
	`Real Estate Agencies`,
	`Residential`,
	`Retirement Homes / Homecare`,
	`School / College / University`,
	`Software Company`,
	`Sports Center / Sports Facilities`,
	`Transport`,
	`Utility / Manufacturing Plant`,
	`Other`
];

const REASON_OF_INTEREST = [
	`I am a software company`,
	`I am a SALTO KS Installer`,
	`I am a SALTO KS End-user`,
	`I am an (Aspiring) KSConnect partner`
]

export {
	BUSINESS_TYPES,
	IMAGE_SIZES,
	UNSUPPORTED_IMAGE_FORMATS,
	REASON_OF_INTEREST
};
